import React, { useRef, useState, useEffect, useContext } from "react";
import { Params, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

import CarouselItem from "../../Components/Carousels/CarouselItem";
import NewsHeader from "../../Components/NewsHeader";
import Heading from "../../Components/UI/Text/Heading";
import InfiniteScrollLoader from "../../Components/UI/PageLoaders/InfiniteScrollLoader";

import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import ICarouselDTO from "../../Models/DTOs/ICarouselDTO";
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";

import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { GetLatestVideo } from "../../Api/Video";
import { GetEpisodesByYear } from "../../Api/Carousel";
import { RoutePaths } from "../../Constants/RoutePaths";
import { AxiosError } from "axios";

const Container = styled.section`
    flex: 1;
`;

const VideoContainer = styled.div`
    .infinite-scroll-component {
        display: flex;
        position: relative;
        align-items: flex-start;
        gap: 5px;
        padding: 15px 10px;
        flex-flow: row wrap;
    }

    //Each video item
    .infinite-scroll-component a {
        flex: 0 1 calc(20% - 4px);
    }

    h1 {
        flex-basis: 100%;
        margin: 0;
    }

    @media only screen and (max-width: 1600px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(25.25% - 8px);
        }
    }

    @media only screen and (max-width: 1000px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(33.8% - 8px);
        }
    }

    @media only screen and (max-width: 800px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(50.6% - 8px);
        }
    }
    @media only screen and (max-width: 600px) {
        .infinite-scroll-component a {
            flex: 0 1 100%;
        }
    }
`;

function NewsDetailsScreen() {
    const params = useParams();
    const seriesId = Number(params.id);
    const controller = new AbortController();
    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();

    const [episodes, setEpisodes] = useState<{ Carousel: ICarouselDTO; DisplayTitle: boolean }[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [currentYears, setCurrentYears] = useState<string[]>([]);
    const iteration = useRef<number>(0);
    const skip = useRef<number>(0);

    const [latestVideo, setLatestVideo] = useState<IVideoDTO | null>(null);
    const [loadingLatest, setLoadingLatest] = useState<boolean>(true);

    useEffect(() => {
        // If user is not authenticated or doesn't have valid subscription, redirect
        // For example, if the user must be authenticated and have a valid subscription:
        if (!authCtx.userData || !authCtx.userData.AspNetUserId) {
            // Not authenticated
            navigate(RoutePaths.Root);
            return;
        }

        // If we have a subscription check (AuthValidSubOnly previously),
        // and userData does not meet the subscription criteria, redirect.
        // e.g. if ( !HasValidSubscription(authCtx.userData) ) { navigate(RoutePaths.Somewhere); return; }
        // Adjust according to your logic.

        async function fetchLatestVideo() {
            setLoadingLatest(true);
            const video = await GetLatestVideo(seriesId, authCtx.userData.CurrentCountryCode, controller);

            if (video === null || video instanceof AxiosError) {
                // No videos found, redirect to news
                navigate(RoutePaths.News);
                return;
            }

            setLatestVideo(video);
            setLoadingLatest(false);
        }

        fetchLatestVideo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.userData]);

    async function EpisodesByYear() {
        let take = 15;
        const result = await GetEpisodesByYear(
            seriesId,
            authCtx.userData.CurrentCountryCode,
            authCtx.userData.AspNetUserId,
            take,
            skip.current,
            iteration.current,
            controller,
            'd'
        );

        if (!result || result instanceof AxiosError || !result.Content || result.Content.length <= 0) {
            setHasMore(false);
            return;
        }

        const year = result.Title.toLowerCase().split("from ")[1].trim();
        let displayTitle = true;

        if (!currentYears.includes(year)) {
            skip.current = result.Content.length;
            setCurrentYears((prev) => [...prev, year]);
        } else {
            skip.current += result.Content.length;
            displayTitle = false;
        }

        if (result.Content.length < take) {
            skip.current = 0;
            iteration.current = iteration.current + 1;
        }

        setEpisodes((prev) => [...prev, { Carousel: result, DisplayTitle: displayTitle }]);
    }

    function RenderCarouselItems(items: ICarouselContentDTO[]) {
        return items.map((content) => <CarouselItem key={content.Id} Content={content} />);
    }

    if (loadingLatest) {
        return <Heading>...Loading latest</Heading>;
    }

    return (
        <Container>
            {latestVideo && <NewsHeader latestVideo={latestVideo} />}
            <VideoContainer>
                <InfiniteScroll
                    dataLength={episodes.length}
                    hasMore={hasMore}
                    next={EpisodesByYear}
                    loader={<InfiniteScrollLoader />}
                >
                    {episodes.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {item.DisplayTitle ? (
                                    <Heading>{item.Carousel.Title}</Heading>
                                ) : null}
                                {RenderCarouselItems(item.Carousel.Content)}
                            </React.Fragment>
                        );
                    })}
                </InfiniteScroll>
            </VideoContainer>
        </Container>
    );
}

export default NewsDetailsScreen;

export async function Loader({ params }: { params: Readonly<Params<string>> }) {
    // No UserSnapshot or AuthValidSubOnly calls here.
    // Authentication checks and data fetching handled inside the component.
    return null;
}
