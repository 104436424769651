import React from "react";

const IconArrowWithTail = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={ props?.iconFill }
                fillRule="nonzero"
                d="M13.022 14.999v3.251a.751.751 0 001.27.544l6.396-6.069a.998.998 0 000-1.45l-6.396-6.068a.752.752 0 00-1.27.543v3.251h-9.02c-.531 0-1.002.47-1.002 1v3.998c0 .53.471 1 1.002 1z"
            > </path>
        </svg>
    )
};

export default IconArrowWithTail;
