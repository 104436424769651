import React from "react";

const IconTimeBackwards = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M3.503 6.726A9.996 9.996 0 0111.998 2c5.518 0 9.997 4.48 9.997 9.997 0 5.519-4.479 9.999-9.997 9.999-5.245 0-9.553-4.048-9.966-9.188-.024-.302.189-.811.749-.811.391 0 .715.3.747.69.351 4.369 4.012 7.809 8.47 7.809 4.69 0 8.497-3.808 8.497-8.499 0-4.689-3.807-8.497-8.497-8.497a8.495 8.495 0 00-7.206 3.995l1.991.005a.75.75 0 010 1.5H2.75A.75.75 0 012 8.25V4.201a.75.75 0 011.5 0zm7.487.021l.007 5.563c0 .288.165.55.424.675l3.978 1.928a.75.75 0 10.652-1.35l-3.555-1.725-.006-5.093a.75.75 0 00-1.5.002z"
            > </path>
        </svg>
    )
};

export default IconTimeBackwards;
