import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { NavLink } from "react-router-dom";

// Models
import { ICarouselPresenterItem } from "../../Models/Interfaces/ICarouselPresenterItem";

// Assets
import ButtonsPrevNext from "../Buttons/ButtonsPrevNext";

type CarouselPresenterProps = {
    isDarkMode: boolean;
    heading: string;
    carouselItems: ICarouselPresenterItem[];
};

const CarouselPortrait: React.FC<CarouselPresenterProps> = ({ isDarkMode, heading, carouselItems }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const
        [currentIndex, setCurrentIndex] = useState(0),
        [itemsPerView, setItemsPerView] = useState(2),
        [containerWidth, setContainerWidth] = useState(0),

        updateItemsPerView = useCallback(() => {
            const width = window.innerWidth;
            if (width < 320) {
                return 1;
            } else if (width < 760) {
                return 2;
            } else if (width < 1008) {
                return 3;
            } else if (width < 1272) {
                return 4;
            } else {
                return 5;
            }
        }, []),

        // Calculate maxIndex based on the number of items and items per view
        maxIndex = Math.max(0, carouselItems.length - itemsPerView),

        goToPrev = () => {
            setCurrentIndex((prev) => Math.max(prev - itemsPerView, 0));
        },

        goToNext = () => {
            setCurrentIndex((prev) => Math.min(prev + itemsPerView, maxIndex));
        },

        handlers = useSwipeable({
            onSwipedLeft: () => goToNext(),
            onSwipedRight: () => goToPrev(),
            trackMouse: true,
        });

    // On mount and on resize, update container width and items per view
    useEffect(() => {
        const handleResize = () => {
            const newItemsPerView = updateItemsPerView();
            setItemsPerView(newItemsPerView);
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [updateItemsPerView]);

    useEffect(() => {
        const newMaxIndex = Math.max(0, carouselItems.length - itemsPerView);
        setCurrentIndex((prevIndex) => Math.min(prevIndex, newMaxIndex));
    }, [itemsPerView, carouselItems.length]);

    // If containerWidth is known, calculate pixel-based layout
    const itemWidth = containerWidth && itemsPerView ? containerWidth / itemsPerView : 0;
    const trackWidth = itemWidth * carouselItems.length;
    const translateX = -currentIndex * itemWidth;

    return (
        <div
            className={`layout--carousel-presenter ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'}`}
            {...handlers}
        >
            <h2>
                {heading}
            </h2>

            <div ref={containerRef} className="carousel-presenter__inner">
                <div
                    className="carousel-presenter__track"
                    style={{
                        width: trackWidth ? `${trackWidth}px` : 'auto',
                        transform: `translateX(${translateX}px)`,
                    }}
                >
                    {carouselItems.map((item, i) => (
                        <div
                            key={i}
                            className="carousel-presenter__item"
                            style={{width: `${itemWidth}px`}}
                        >
                            <NavLink to={item.link} className="u-relative_hidden">
                                <div
                                    className="u-full_cover_absolute"
                                    style={{
                                        background: `url("${item.bgImage}") center/cover no-repeat`
                                    }}
                                />

                                { item.label !== "" && (<label><span>{item.label}</span></label>)}
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>

            <ButtonsPrevNext
                onPrev={goToPrev}
                onNext={goToNext}
                currentIndex={currentIndex}
                maxIndex={maxIndex}
                parentClass="carousel-presenter__controls"
            />
        </div>
    );
};

export default CarouselPortrait;
