import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { ContainsSpecialChars, ContainsUpperCase } from "../../../Helpers/Utility";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import PinkButton from "../Buttons/PinkButton";
import Input from "../Inputs/Input";
import SubAlert from "./SubAlert";
import { IChangePasswordDTO } from "../../../Models/DTOs/IChangePasswordDTO";
import SuccessText from "../Text/SuccessText";
import { ChangePassword } from "../../../Api/Account";
import { AxiosError } from "axios";
import styled from "styled-components";

// Contest
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

const Form = styled.form`
    max-width: calc(600rem/16);
    margin: 0 auto;
`;

function ChangePasswordForm() {
    const currentPassRef = useRef<HTMLInputElement | null>(null);
    const newPassRef = useRef<HTMLInputElement | null>(null);
    const confirmPassRef = useRef<HTMLInputElement | null>(null);
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();
    const [formIsValid, setFormIsValid] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentErrors, setCurrentErrors] = useState<string>();
    const [newErrors, setNewErrors] = useState<string>();
    const [confirmErrors, setConfirmErrors] = useState<string>();

    function GetCurrentValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setCurrentErrors("Current password can not be empty.");
            return InputIsValid.Invalid;
        }

        setCurrentErrors(undefined);
        return InputIsValid.Valid;
    }

    function GetNewValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setNewErrors("New password cannot be empty.");
            return InputIsValid.Invalid;
        }

        if (text.length < 8 || text.trim().length < 8) {
            setNewErrors("New password must be at least 8 characters.");
            return InputIsValid.Invalid;
        }

        if (!ContainsUpperCase(text)) {
            setNewErrors("New password must contain atleast 1 upper case character");
            return InputIsValid.Invalid;
        }

        if (!ContainsSpecialChars(text)) {
            setNewErrors("New password must contain a special character.");
            return InputIsValid.Invalid;
        }

        setNewErrors(undefined);
        return InputIsValid.Valid;
    }

    function GetConfirmValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setConfirmErrors("Confirm password can not be empty.");
            return InputIsValid.Invalid;
        }

        if (text !== newPasswordState.Value) {
            setConfirmErrors("Passwords do not match.");
            return InputIsValid.Invalid;
        }

        setConfirmErrors(undefined);
        return InputIsValid.Valid;
    }

    function currentPasswordReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetCurrentValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetCurrentValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function newPasswordReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetNewValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetNewValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function confirmPasswordReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetConfirmValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetConfirmValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    const [currentPasswordState, dispatchCurrentPassword] = useReducer(
        currentPasswordReducer,
        {
            Value: "",
            IsValid: InputIsValid.NotSet,
        } as IInputDTO
    );

    const [newPasswordState, dispatchNewPassword] = useReducer(
        newPasswordReducer,
        {
            Value: "",
            IsValid: InputIsValid.NotSet,
        } as IInputDTO
    );

    const [confirmPasswordState, dispatchConfirmPassword] = useReducer(
        confirmPasswordReducer,
        {
            Value: "",
            IsValid: InputIsValid.NotSet,
        } as IInputDTO
    );

    const currentPasswordIsValid =
        currentPasswordState.IsValid === InputIsValid.Valid ||
        currentPasswordState.IsValid === InputIsValid.NotSet;

    const newPasswordIsValid =
        newPasswordState.IsValid === InputIsValid.Valid ||
        newPasswordState.IsValid === InputIsValid.NotSet;

    const confirmPasswordIsValid =
        confirmPasswordState.IsValid === InputIsValid.Valid ||
        confirmPasswordState.IsValid === InputIsValid.NotSet;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(currentPasswordIsValid && newPasswordIsValid && confirmPasswordIsValid);
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [currentPasswordIsValid, newPasswordIsValid, confirmPasswordIsValid]);

    function currentPasswordChangeHandler(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        dispatchCurrentPassword({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    const validateCurrentPasswordHandler = () => {
        dispatchCurrentPassword({
            Value: currentPasswordState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    };

    function newPasswordChangeHandler(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        dispatchNewPassword({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    const validateNewPasswordHandler = () => {
        dispatchNewPassword({
            Value: newPasswordState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    };

    function confirmPasswordChangeHandler(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        dispatchConfirmPassword({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    const validateConfirmPasswordHandler = () => {
        dispatchConfirmPassword({
            Value: confirmPasswordState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    };

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsLoading(true);

        dispatchCurrentPassword({
            Type: InputState.Input_Blur,
            Value: currentPasswordState.Value,
        } as IInputDTO);

        dispatchNewPassword({
            Type: InputState.Input_Blur,
            Value: newPasswordState.Value,
        } as IInputDTO);

        dispatchConfirmPassword({
            Type: InputState.Input_Blur,
            Value: confirmPasswordState.Value,
        } as IInputDTO);

        const valuesNotUnset = currentPasswordState.IsValid !== InputIsValid.NotSet && newPasswordState.IsValid !== InputIsValid.NotSet && confirmPasswordState.IsValid !== InputIsValid.NotSet;

        if (formIsValid && valuesNotUnset && authCtx?.userData) {

            const data = {
                OldPassword: currentPasswordState.Value,
                NewPassword: newPasswordState.Value,
                ConfirmPassword: confirmPasswordState.Value
            } as IChangePasswordDTO;

            const result = await ChangePassword(data, authCtx?.userData?.Access_Token, controller);
            let success;

            if(result instanceof AxiosError){
                success = false;
                setSuccess(false);
            }
            else{
                success = result;
                setSuccess(result);
            }

            if (!success) {
                setIsLoading(false);
                setCurrentErrors("Password failed to update");
                return;
            }

            setCurrentErrors(undefined);

            //Resets input
            dispatchCurrentPassword({
                Type: InputState.Not_Set,
                Value: "",
            } as IInputDTO);

            dispatchNewPassword({
                Type: InputState.Not_Set,
                Value: "",
            } as IInputDTO);

            dispatchConfirmPassword({
                Type: InputState.Not_Set,
                Value: "",
            } as IInputDTO);

        } else if (!currentPasswordIsValid) {
            currentPassRef?.current?.focus();
        }
        else if (!newPasswordIsValid) {
            newPassRef?.current?.focus();
        }
        else if (!confirmPasswordIsValid) {
            confirmPassRef?.current?.focus();
        }

        setIsLoading(false);
    }

    return (
        <Form onSubmit={OnSubmit}>
            <Input
                ref={currentPassRef}
                isValid={currentPasswordIsValid}
                onBlur={validateCurrentPasswordHandler}
                onChange={currentPasswordChangeHandler}
                value={currentPasswordState.Value}
                type="password"
                placeholder="Current password..."
                errorMessage={currentErrors}
            />
            <Input
                ref={newPassRef}
                isValid={newPasswordIsValid}
                onBlur={validateNewPasswordHandler}
                onChange={newPasswordChangeHandler}
                value={newPasswordState.Value}
                type="password"
                placeholder="Minimum of 8 characters.."
                errorMessage={newErrors}
            />
            <Input
                ref={confirmPassRef}
                isValid={confirmPasswordIsValid}
                onBlur={validateConfirmPasswordHandler}
                onChange={confirmPasswordChangeHandler}
                value={confirmPasswordState.Value}
                type="password"
                placeholder="Confirm New Password..."
                errorMessage={confirmErrors}
            />

            {success ?
                (
                    <SuccessText>
                        Success! Password changed successfully!
                    </SuccessText>
                )
                : false}

            <SubAlert>
                Password needs to be at least 8 characters, with at least one
                uppercase, a lowercase, a number and a special character.
            </SubAlert>

            <PinkButton
                disabled={
                    !currentPasswordIsValid ||
                    !newPasswordIsValid ||
                    !confirmPasswordIsValid ||
                    isLoading
                }
                testId="changePasswordBtn"
            >
                Save Changes
            </PinkButton>
        </Form>
    );
}

export default ChangePasswordForm;
