import { useContext } from "react";
import UserOnboarding from "../../Components/GlobalUI/UserOnboarding";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { GlobalInterfaceContext } from "../../Context/GlobalInterfaceContext";
import SliderFeaturedContent from "../../Components/Layouts/SliderFeaturedContent";
import CarouselPortrait from "../../Components/Layouts/CarouselPortrait";
import CarouselLandscape from "../../Components/Layouts/CarouselLandscape";
import {Colours} from "../../Constants/Colours";

function BrowserScreen() {
    const
        globalInterfaceContext = useContext(GlobalInterfaceContext),
        authCtx = useContext(UserAuthenticationContext);

    return (
        <>
            {/*<UserOnboarding isDarkMode={globalInterfaceContext?.isDarkMode} />*/}

            <div className="page--browse">
                <SliderFeaturedContent isDarkMode={globalInterfaceContext.isDarkMode}/>

                <div className="page-browse__carousel-container">
                    <CarouselPortrait
                        heading="Weekly Shows"
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        carouselItems={dummyWeeklyShows}
                    />
                </div>

                <div className="page-browse__carousel-container">
                    <CarouselLandscape
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        heading="Just Released"
                    />
                </div>

                <div className="page-browse__carousel-container">
                    <CarouselLandscape
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        heading="Continue Watching"
                    />
                </div>

                <div className="page-browse__carousel-container">
                    <CarouselLandscape
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        heading="Top Rated"
                    />
                </div>

                <div className="page-browse__carousel-container">
                    <CarouselPortrait
                        heading="Films"
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        carouselItems={dummyFilms}
                    />
                </div>

                <div className="page-browse__carousel-container">
                    <CarouselLandscape
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        accentColor={Colours.GlobalCurrentEvents}
                        heading="Global Current Events"
                    />
                </div>

                <div className="page-browse__carousel-container">
                    <CarouselLandscape
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        accentColor={Colours.Consciousness}
                        heading="Consciousness"
                    />
                </div>

                <div className="page-browse__carousel-container">
                    <CarouselLandscape
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        accentColor={Colours.HealthAndWellbeing}
                        heading="Health & Wellbeing"
                    />
                </div>

                <div className="page-browse__carousel-container">
                    <CarouselLandscape
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        accentColor={Colours.HiddenKnowledge}
                        heading="Hidden Knowledge"
                    />
                </div>
            </div>
        </>
    );
}

export default BrowserScreen;


const dummyWeeklyShows = [
    {
        label: "Latest Episode: 09/12",
        bgImage: "https://storage.ickonic.com/IckonicImage/compressed/576b12f8-2e39-4ec2-8152-2fff8575394a_s_121.jpg",
        link: ""
    },
    {
        label: "Latest Episode: 09/12",
        bgImage: "https://storage.ickonic.com/IckonicImage/compressed/Classified Portrait Thumbnail v1.jpg", link: ""},
    {label: "Latest Episode: 08/12", bgImage: "https://storage.ickonic.com/IckonicImage/compressed/e5463081-41a2-4428-b072-a6a44ad949d3_s_148.jpg", link: ""},
    {label: "Latest Episode: 07/12", bgImage: "https://storage.ickonic.com/IckonicImage/compressed/WTAF%20-%20Portrait%20[For%20Website]%202.jpg", link: ""},
    {label: "Latest Episode: 07/12", bgImage: "https://storage.ickonic.com/IckonicImage/compressed/Health%20Code%20-%20Portrait%20[For%20Website].jpg", link: ""},
    {label: "Latest Episode: 08/12", bgImage: "https://storage.ickonic.com/IckonicImage/compressed/e5463081-41a2-4428-b072-a6a44ad949d3_s_148.jpg", link: ""},
    {label: "Latest Episode: 09/12", bgImage: "https://storage.ickonic.com/IckonicImage/compressed/576b12f8-2e39-4ec2-8152-2fff8575394a_s_121.jpg", link: ""},
    {label: "Latest Episode: 07/12", bgImage: "https://storage.ickonic.com/IckonicImage/compressed/Health%20Code%20-%20Portrait%20[For%20Website].jpg", link: ""},
    {label: "Latest Episode: 08/12", bgImage: "https://storage.ickonic.com/IckonicImage/compressed/e5463081-41a2-4428-b072-a6a44ad949d3_s_148.jpg", link: ""},
    {label: "Latest Episode: 09/12", bgImage: "https://storage.ickonic.com/IckonicImage/compressed/576b12f8-2e39-4ec2-8152-2fff8575394a_s_121.jpg", link: ""},
];

const dummyFilms = [
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
    {label: "", bgImage: "", link: ""},
];
