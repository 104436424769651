import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

// Models
import { InputState } from "../../../Models/Enums/InputState";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";

// Helpers
import { ContainsAt, SetTitle } from "../../../Helpers/Utility";
import { RedirectStringToRoutePath } from "../../../Helpers/Utility";

// Assets
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IckonicLogo } from "../../../Assets/Images/Logos/Logos";
import IconSpinLoader from "../../../Assets/SVGs/Icons/SpinLoader";
import IconCheckMark from "../../../Assets/SVGs/Icons/CheckMark";

// Constants
import { RoutePaths } from "../../../Constants/RoutePaths";
import { Ease } from "../../../Constants/EasingCurves";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

// Components
import Card from "../../../Components/UI/Card";
import WhiteButton from "../../../Components/UI/Buttons/WhiteButton";
import InputWithLabel from "../../../Components/UI/Inputs/InputWithLabel";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import Heading from "../../../Components/UI/Text/Heading";
import CheckBoxWithLabel from "../../../Components/UI/Inputs/CheckBoxWithLabel";
import NavLinkPinkTextButton from "../../../Components/UI/Buttons/NavLinkPinkTextButton";
import InputWithIcon from "../../../Components/UI/Inputs/InputWithIcon";

// Api
import { Login } from "../../../Api/Account";

// Context
import { UserAuthenticationContext } from "../../../Context/UserAuthenticationContext";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    background: ${ Colours.SecondaryDarker };
    * {
        box-sizing: border-box;
    }

    a {
        font-size: 12pt;
        color: ${ Colours.IckonicPinkHighlight };
    }

    > div {
        max-width: 600px;
        transform: translateY(-4vh);
    }

    input::placeholder {
        color: ${Colours.LighterGrey};
    }

    .form__submit {
        min-height: 38px;
        padding: 12px;
        position: relative;
        margin: 13px 0 22px 0;
        width: 100%;
        display: block;
        position: relative;
        transition: width .25s ${ Ease.Smooth }, left .25s ${ Ease.Smooth }, border-radius 0s linear .25s;
        left: 0;
        &.has-success {
            position: relative;
            background: ${ Colours.IckonicPink };
            box-shadow: 0 0 0 calc(2rem/16) ${ Colours.IckonicPink };
            width: 38px !important;
            height: 38px;
            left: calc(50% - (19rem/16));
            .button__icon {
                border-radius: 50%;
                animation-name: pulseHuge;
                animation-duration: 700ms;
                animation-timing-function: ${ Ease.LateSnap };
            }
        }

        .button__icon {
            width: 25px;
            height: 25px;
            margin: 0 auto;
            position: absolute;
            top: calc(50% - (13rem/16));
            left: calc(50% - (13rem/16));
            * {
                fill: black;
            }

            &.icon--loading {
                animation-name: spin;
                animation-duration: 1200ms;
                animation-iteration-count: infinite;
                animation-timing-function: ${ Ease.Smooth };
                * {
                    fill: white;
                }
            }
        }
    }

    > div {
        margin: 75px 0 35px 0;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        background: ${ Colours.Secondary };
        padding: 0;
        > div {
            transform: translateY(0);
        }
    }

    @media screen and (min-height: calc(800em/16)) {
        > div {
            transform: translateY(-8vh);
        }
    }
`;

const TextContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem 0;
    p {
        width: calc(50% - 8px);
        text-align: right;
        margin: 0 0 0 auto;
        max-width: 120px;
    }

    h1 {
        margin: 0;
        width: calc(50% - 8px);
        font-size: 22pt;
    }

    @media screen and (min-width: calc(350em/16)) {
        p {
            width: calc(60% - 8px);
            max-width: none;
        }

        h1 {
            width: calc(40% - 8px);
        }
    }
`;

const LoginForm = styled.form`
    a {
        color: ${ Colours.TertiaryHighlight };
    }
`;

const OptionsContainer = styled.div`
    margin: 1rem 0 0 0;
`;

const LogoContainer = styled(NavLink)`
    position: absolute;
    top: 24px;
    left: calc(50% - (55rem/16));
    width: 110px;
    height: 30px;
    path {
        fill: ${ Colours.Text };
        transition: fill .3s ${ Ease.Smooth };
    }

    &:hover,
    &:focus {
        path {
            fill: ${ Colours.IckonicPinkHighlight };
        }
    }
`;

const LoginScreen = () => {
    const
        { redirectTo } = useParams<string>(),
        controller = new AbortController(),
        authCtx = useContext(UserAuthenticationContext),
        [formIsValid, setFormIsValid] = useState(false),
        [loading, setLoading] = useState(false),
        [success, setSuccess] = useState(false),
        [rememberMe, setRememberMe] = useState(true),
        [emailError, setEmailError] = useState<string>(),
        [passwordError, setPasswordError] = useState<string>(),
        [seePassword, setSeePassword] = useState<boolean>(false),
        navigate = useNavigate(),
        emailInputRef = useRef<HTMLInputElement | null>(null),
        passwordInputRef = useRef<HTMLInputElement | null>(null);

    const RedirectAfterLogin = () => {
        if (redirectTo === undefined || redirectTo === null) {
            return navigate(RoutePaths.Browse);
        }

        return navigate(RedirectStringToRoutePath(redirectTo));
    };

    const GetEmailValidState = (text: string) : InputIsValid => {
        if (text.length <= 0 || text.trim().length <= 0) {
            setEmailError("E-mail cannot be empty");
            return InputIsValid.Invalid;
        }

        if (!ContainsAt(text)) {
            setEmailError("E-mail is not valid");
            return InputIsValid.Invalid;
        }

        setEmailError(undefined);
        return InputIsValid.Valid;
    };

    const GetEmailKeyUpState = (text: string) : InputIsValid => {
        if (emailError === '' || emailError === undefined || emailError === null) {
            return InputIsValid.Valid;
        }
        return GetEmailValidState(text);
    };

    const GetPasswordKeyUpState = (text:string) : InputIsValid => {
        if (passwordError === '' || passwordError === undefined || passwordError === null) {
            return InputIsValid.Valid;
        }
        return GetPasswordValidState(text);
    };

    const GetPasswordValidState = (text: string) => {
        if (text.length <= 0 || text.trim().length <= 0) {
            setPasswordError("Password cannot be empty");
            return InputIsValid.Invalid;
        }

        if (text.length < 8) {
            setPasswordError("Password must be at least 8 characters");
            return InputIsValid.Invalid;
        }

        setPasswordError(undefined);
        return InputIsValid.Valid;
    };

    const SetRememberMeFn = (value: boolean) => {
        setRememberMe(value);
    };

    const EmailReducer = (state: IInputDTO, action: IInputDTO) => {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetEmailKeyUpState(state.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetEmailValidState(state.Value),
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    };

    const PasswordReducer = (state: IInputDTO, action: IInputDTO) => {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetPasswordKeyUpState(action.Value.trim()),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetPasswordValidState(state.Value.trim()),
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    };

    const [ emailState, dispatchEmail ] = useReducer(EmailReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [ passwordState, dispatchPassword ] = useReducer(PasswordReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const emailIsValid =
        emailState.IsValid === InputIsValid.Valid ||
        emailState.IsValid === InputIsValid.NotSet;

    const passwordIsValid =
        passwordState.IsValid === InputIsValid.Valid ||
        passwordState.IsValid === InputIsValid.NotSet;

    const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatchEmail({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    };

    const passwordChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        dispatchPassword({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    };

    const validateEmailHandler = () => {
        dispatchEmail({ Type: InputState.Input_Blur } as IInputDTO);
    };

    const validatePasswordHandler = () => {
        dispatchPassword({ Type: InputState.Input_Blur } as IInputDTO);
    };

    const SubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        dispatchEmail({ Type: InputState.Input_Blur, Value: emailState.Value, } as IInputDTO);
        dispatchPassword({ Type: InputState.Input_Blur, Value: passwordState.Value, } as IInputDTO);

        if (
            formIsValid &&
            passwordState.IsValid !== InputIsValid.NotSet &&
            emailState.IsValid !== InputIsValid.NotSet
        ) {
            const successResp = await Login(emailState.Value, passwordState.Value, controller);
            setSuccess(typeof successResp === "string");

            if (!successResp) {
                setEmailError("Failed To Log in. Please check your details are correct.");
            } else {
                if (rememberMe && typeof successResp === "string") {
                    authCtx.storeToken(successResp);
                }

                setTimeout(() => {
                    RedirectAfterLogin();
                }, 1700);
            }
        } else if (!emailIsValid) {
            emailInputRef?.current?.focus();
        } else {
            passwordInputRef.current?.focus();
        }

        setLoading(false);
    };

    const TogglePassword = () => {
        setSeePassword(!seePassword);
    };

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(emailIsValid && passwordIsValid);
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [emailIsValid, passwordIsValid]);

    // If user is already authenticated, redirect them.
    useEffect(() => {
        if (authCtx.userData && authCtx.userData.AspNetUserId && authCtx.userData.AspNetUserId.length > 0) {
            navigate(RoutePaths.Browse);
        }
    }, [authCtx.userData, navigate]);

    return (
        <Container>
            <LogoContainer to="/">
                { IckonicLogo }
            </LogoContainer>

            <Card>
                <TextContainer>
                    <Heading type={ HeadingType.H1 }>Sign in</Heading>
                    <PrimaryText>
                        Not A Member? <NavLinkPinkTextButton to={ RoutePaths.CreateYourAccount }>Sign Up!</NavLinkPinkTextButton>
                    </PrimaryText>
                </TextContainer>

                <LoginForm onSubmit={ SubmitHandler }>
                    <InputWithLabel
                        ref={ emailInputRef }
                        id="email"
                        type="email"
                        label="Email Address"
                        placeholder="you@example.com"
                        value={ emailState.Value }
                        isValid={
                            (success && emailError === undefined) ||
                            (emailIsValid &&
                                !success &&
                                emailError === undefined)
                        }
                        onChange={ emailChangeHandler }
                        onBlur={ validateEmailHandler }
                        errorMessage={ emailError }
                    />

                    <InputWithIcon
                        ref={ passwordInputRef }
                        label="Password"
                        placeholder="******"
                        type={ seePassword ? "text" : "password" }
                        isValid={
                            passwordIsValid ||
                            passwordState.IsValid === InputIsValid.NotSet
                        }
                        value={ passwordState.Value }
                        onChange={ passwordChangeHandler }
                        onBlur={ validatePasswordHandler }
                        onIconClick={ TogglePassword}
                        errorMessage={ passwordError }
                    >
                        { seePassword ? <FaEye /> : <FaEyeSlash /> }
                    </InputWithIcon>

                    <NavLinkPinkTextButton to={ RoutePaths.ForgotPassword }>
                        Forgot Password?
                    </NavLinkPinkTextButton>

                    <WhiteButton
                        className={ `form__submit ${ success ? 'has-success' : 'is-waiting-for-login' }` }
                        disabled={
                            !formIsValid ||
                            passwordState.IsValid === InputIsValid.NotSet ||
                            emailState.IsValid === InputIsValid.NotSet ||
                            loading
                        }
                    >
                        { success ?
                            <div className="button__icon icon--success">{ IconCheckMark() }</div>
                            :
                            loading ?
                                <div className="button__icon icon--loading">{ IconSpinLoader() }</div>
                                :
                                'Sign In'
                        }
                    </WhiteButton>

                    <OptionsContainer>
                        <CheckBoxWithLabel
                            text="Remember Me"
                            checked={ rememberMe }
                            setChecked={ SetRememberMeFn }
                        />
                    </OptionsContainer>
                </LoginForm>
            </Card>
        </Container>
    );
};

export default LoginScreen;

export const Loader = async () => {
    SetTitle("Login");
    // No more UserSnapshot or UnauthOnly calls here. Authentication checks are handled in the component.
    return null;
};
