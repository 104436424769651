import React from "react";

// Components
import ButtonPillUppercase from "../Buttons/ButtonPillUppercase";

type NotificationBarProps = {
    isDarkMode: boolean;
};

const NotificationBar: React.FC<NotificationBarProps> = ({ isDarkMode }) => {
    return (
        <div
            className={`global__notification-bar ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'}`}
        >
            <p>
                Trending: CEO Shooter Deep-Dive
            </p>

            <ButtonPillUppercase label="Watch Now" className="" link="Watch/1245" />
        </div>
    );
};

export default NotificationBar;
