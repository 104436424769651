import { RoutePaths } from "./RoutePaths";

export const PagesWithoutFooter = [
    RoutePaths.Renew3DSConfirm.toLowerCase(),
    RoutePaths.Login.toLowerCase(),
    RoutePaths.CreateYourAccount.toLowerCase(),
    RoutePaths.CreateYourAccountGameChanger.toLowerCase(),
    RoutePaths.CreateYourAccountTruthSeeker.toLowerCase(),
    RoutePaths.ChoosePlan.toLowerCase(),
    RoutePaths.ConfirmSubscription.toLowerCase(),
];
