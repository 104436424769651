import React from "react";

const IconSM58 = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path fill={props?.iconFill}
                  d="M3.979 15.045c-1.468.978-2.168 2.263-2.231 3.955H0c.069-2.346 1.1-4.186 3.153-5.497l.826 1.542zM19.339 1c-1.852 0-3.198.966-4.138 2.619l3.545 6.618c3.4.222 5.254-2.15 5.254-4.575A4.654 4.654 0 0019.339 1zM4.472 11.728l1.557 2.906 10.539-4.229-2.716-5.07-9.38 6.393zm4.765 3.773c.633.619.764 1.648.764 2.558V23H12v-5.097c0-1.776.662-3.024 1.735-4.207l-4.498 1.805z"></path>
        </svg>
    )
};

export default IconSM58;
