import { RoutePaths } from "./RoutePaths";

export const PagesWithoutPopups = [
    RoutePaths.CreateYourAccount.toLowerCase(),
    RoutePaths.CreateYourAccountGameChanger.toLowerCase(),
    RoutePaths.CreateYourAccountTruthSeeker.toLowerCase(),
    RoutePaths.ChoosePlan.toLowerCase(),
    RoutePaths.ConfirmSubscription.toLowerCase(),
    RoutePaths.Renew3DSConfirm.toLowerCase(),
    RoutePaths.Renew.toLowerCase(),
    RoutePaths.Login.toLowerCase(),
    RoutePaths.AndroidTV.toLowerCase(),
    RoutePaths.SamsungTV.toLowerCase(),
    RoutePaths.Roku.toLowerCase(),
    RoutePaths.Firestick.toLowerCase(),
];
