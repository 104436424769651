import { PaymentMethod } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import { useContext, useState } from "react";
import styled from "styled-components";
import { AddPaymentMethod } from "../../../Api/Stripe";
import { Colours } from "../../../Constants/Colours";
import { PaymentType } from "../../../Models/Enums/PaymentType";
import PinkButton from "../Buttons/PinkButton";
import StripeCard from "../StripeCard";
import Heading from "../Text/Heading";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const TypeContainer = styled.div<{hide: boolean}>`
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    display: ${p => p.hide ? "none": "block" };

    > h1 {
        cursor: pointer;
        transition-duration: 200ms;
        transition-property: color, opacity;
        transition-timing-function: ease-in-out;
    }

    > h1.active {
        color: ${Colours.IckonicPink};
    }

    > h1:hover {
        opacity: 0.5;
        color: ${Colours.IckonicPink};
    }
`;

const StripeCardContainer = styled.div`
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    padding: 0 20% 0;

    @media only screen and (max-width: 900px) {
        padding: 5% 0 0;
    }
`;

function SelectPaymentMethod(props: {
    setSelectedPaymentMethod: (paymentMethod: string) => void,
    allowUseExistingCard?: boolean,
}) {
    const allowUseExistingCard = props.allowUseExistingCard ?? true;
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();
    const [paymentType, setPaymentType] = useState<PaymentType>(
        PaymentType.New
    );

    function OnPaymentTypeClick(type: PaymentType) {
        setPaymentType(type);
    }

    async function AddStripePaymentMethod(paymentMethod: PaymentMethod) {
        const result = await AddPaymentMethod(
            authCtx.userData.AspNetUserId,
            paymentMethod.id,
            controller
        );

        if(result instanceof AxiosError){
            return false;
        }

        authCtx.refreshUserData(authCtx.userData.Access_Token);

        props.setSelectedPaymentMethod(paymentMethod.id);

        return result;
    }

    function GetComponent() {
        switch (paymentType) {
            case PaymentType.Existing:
                return <PinkButton onClick={() => props.setSelectedPaymentMethod(authCtx.userData.PaymentMethodId)}>Continue</PinkButton>;
            case PaymentType.New:
                return (
                    <StripeCardContainer>
                        <StripeCard setPaymentMethod={AddStripePaymentMethod} />
                    </StripeCardContainer>
                );
            default:
            case PaymentType.None:
                return null;
        }
    }

    return (
        <Container>
            <TypeContainer hide={!allowUseExistingCard}>
                <Heading
                    className={
                        paymentType === PaymentType.New ? "active" : undefined
                    }
                    onClick={() => OnPaymentTypeClick(PaymentType.New)}
                >
                    New Card
                </Heading>

                <Heading
                    className={
                        paymentType === PaymentType.Existing
                            ? "active"
                            : undefined
                    }
                    onClick={() => OnPaymentTypeClick(PaymentType.Existing)}
                >
                    Existing Card
                </Heading>
            </TypeContainer>
            {GetComponent()}
        </Container>
    );
}

export default SelectPaymentMethod;
