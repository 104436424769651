import React from "react";

const IconCopyLink = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M21.921 2.081a7.104 7.104 0 010 10.042l-3.84 3.839-2.121-2.122L19.799 10a4.101 4.101 0 00-.001-5.797 4.103 4.103 0 00-5.797-.001l-3.84 3.839L8.04 5.92l3.84-3.839a7.1 7.1 0 0110.041 0zM13.839 15.96l-3.84 3.839a4.104 4.104 0 01-5.799 0 4.104 4.104 0 010-5.797l3.84-3.84-2.121-2.121-3.84 3.84A7.1 7.1 0 0012.12 21.922l3.84-3.84-2.121-2.122z"> </path>
        </svg>
    )
};

export default IconCopyLink;
