export const IconTripleColumn = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M14.865 16.6c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1V19c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zm-7.135 0c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1V19c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zm14.27 0c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1V19c0 .53.47 1 1 1H21c.53 0 1-.47 1-1zm-11.365.5h2.73v1.4h-2.73zm-7.135 0h2.73v1.4H3.5zm14.27 0h2.73v1.4h-2.73zm-2.905-6.3c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zm-7.135 0c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zm14.27 0c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1H21c.53 0 1-.47 1-1zm-11.365.5h2.73v1.4h-2.73zm-7.135 0h2.73v1.4H3.5zm14.27 0h2.73v1.4h-2.73zM14.865 5c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zM7.73 5c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zM22 5c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1H21c.53 0 1-.47 1-1zm-11.365.5h2.73v1.4h-2.73zM3.5 5.5h2.73v1.4H3.5zm14.27 0h2.73v1.4h-2.73z"
            ></path>
        </svg>
    )
};

export default IconTripleColumn;
