import {useContext} from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Assets
import {IckonicLogo} from "../../Assets/SVGs/Logos";
import IconProfile from "../../Assets/SVGs/Icons/Profile";

// Components
import HeaderMenu from "../Menus/HeaderMenu";
import ButtonPillGradient from "../Buttons/ButtonPillGradient";

// Global state
import {GlobalInterfaceContext} from "../../Context/GlobalInterfaceContext";
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

// Constants
import {RoutePaths} from "../../Constants/RoutePaths";

const Header = () => {
    const

        // Context
        globalInterfaceContext = useContext(GlobalInterfaceContext),
        userAuthContext = useContext(UserAuthenticationContext),
        {t} = useTranslation(),

        // Misc
        location = useLocation();

    return (
        <header className={`header--global
        ${globalInterfaceContext?.isDarkMode ? 'is-dark-mode' : 'has-light-mode'}
        ${globalInterfaceContext?.mainMenuDesktopMode ? 'has-desktop-menu' : 'has-mobile-menu'}
        ${globalInterfaceContext.hasNotificationBar ? 'has-notification-bar' : 'notification-bar-hidden'}
        `}>
            {location.pathname === '/' ?
                (
                    <div className="header__logo">
                        {IckonicLogo()}
                    </div>
                )
                :
                (
                    <Link
                        className="header__logo"
                        to={'/'}
                    >
                        {IckonicLogo()}
                    </Link>
                )
            }

            <div className="header__cover u-full_cover_absolute" />

            <button
                className={`button--burger ${globalInterfaceContext?.mainMenuOpen ? 'menu-is-active' : 'menu-inactive'}`}
                onClick={() => globalInterfaceContext?.toggleMainMenuOpenState(!globalInterfaceContext?.mainMenuOpen)}
            >
                <span>{t('header-ui-hamburger-button-label')}</span>
            </button>

            <HeaderMenu
                isDarkMode={globalInterfaceContext.isDarkMode}
                menuActive={globalInterfaceContext.mainMenuOpen}
                hasNotificationBar={globalInterfaceContext.hasNotificationBar}
            />

            {/*{userAuthContext && (!userAuthContext.userData.AspNetUserId || userAuthContext.userData.SubscriptionStatus === 0) &&*/}
            {/*    (*/}
            {/*        userAuthContext.userData.SubscriptionStatus === 0 ?*/}
            {/*            (*/}
            {/*                <ButtonPillGradient link="" text="Subscribe" className="header__subscribe" background="linear-gradient(90deg, rgba(223,75,220,1) 0%, rgba(229,174,44) 100%)" color="" />*/}
            {/*            )*/}
            {/*            :*/}
            {/*            (*/}
            {/*                <ButtonPillGradient link="/sign-up" text="Sign Up" className="header__subscribe" background="linear-gradient(90deg, rgba(223,75,220,1) 0%, rgba(229,174,44) 100%)" color="" />*/}
            {/*            )*/}
            {/*    )*/}
            {/*}*/}

            <Link to={RoutePaths.Login} className="header__login">
                <span>
                    {!userAuthContext?.userData.AspNetUserId ?
                        t('header-ui-account-button-label')
                        :
                        t('header-ui-sign-in-button-label')
                    }
                </span>

                {IconProfile()}
            </Link>
        </header>
    );
};

export default Header;
