import styled from "styled-components";
import backgroundLooping from "../../../Assets/Images/loopingBG.gif";
import logo from "../../../Assets/Images/Logos/logo-white.svg";
import Heading from "../../../Components/UI/Text/Heading";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import NavLinkPinkButton from "../../../Components/UI/Buttons/NavLinkPinkButton";
import { useContext, useRef, useState, useReducer } from 'react';
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { Colours } from "../../../Constants/Colours";
import { BsTv } from 'react-icons/bs';
import { IsActive, IsAuthenticated } from "../../../Helpers/UserUtility";
import { RoutePaths } from "../../../Constants/RoutePaths";
import PinkButton from "../../../Components/UI/Buttons/PinkButton";
import Input from "../../../Components/UI/Inputs/Input";
import { TvPlatform } from "../../../Models/Enums/TvPlatforms";
import { GetTvPlatformName } from "../../../Helpers/Utility";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputState } from "../../../Models/Enums/InputState";
import { SendCode } from "../../../Api/TvAuth";
import { toast } from "react-toastify";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

const Container = styled.div`
    background-image: url(${backgroundLooping});
    background-size: cover;
    height: 100%;

    align-items: center;
    display: flex;
    flex-direction: column;
    padding:45px;
`;

const Logo = styled.img`
    width: 300px;
`;

const InlineButtonLayout = styled.div`
    a {
        display: inline;
        padding: 5px 15px;
    }

    p {
        line-height: 3;
    }
`;

const LargeButtonLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        min-width:80%;
    }
    button {
        min-width:80%;
        display: block;
    }
`;

const ContentContainer = styled.div`
    margin-top:40px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 25px;
    background: ${Colours.SecondaryHighlight};
    position: relative;
    color: ${Colours.Text};

    svg {
        font-size: 60px;
    }

    h1 {
        margin:15px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: ${ResponsiveBreakpoints.ContainerDesktopWidth + 'px'} ;
    }
`;

function TvAuthenticationScreen(props: {
    platform: TvPlatform
}) {
    const errorText = "We could not log you in, please check the code you entered is valid.";
    const controller = new AbortController();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [codeError, setCodeError] = useState<string>();
    const authCtx = useContext(UserAuthenticationContext);
    const codeInputRef = useRef<HTMLInputElement | null>(null);
    const isAuthenticated = IsAuthenticated(authCtx.userData);
    const isSubscribed = IsActive(authCtx.userData);

    const [codeState, dispatchCode] = useReducer(CodeReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    function GetCodeKeyUpState(text: string) : InputIsValid {
        if (codeError === '' || codeError === undefined || codeError === null) {
            return InputIsValid.Valid;
        }
        return GetCodeValidState(text);
    }

    function GetCodeValidState(text: string) : InputIsValid {
        if (text.length <= 0 || text.trim().length <= 0) {
            setCodeError("The code cannot be empty.");
            return InputIsValid.Invalid;
        }

        setCodeError(undefined);
        return InputIsValid.Valid;
    }

    function CodeReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetCodeKeyUpState(state.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetCodeValidState(state.Value),
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    const validateCodeHandler = () => {
        dispatchCode({ Type: InputState.Input_Blur } as IInputDTO);
    };

    const codeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatchCode({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    };

    const codeIsValid =
        codeState.IsValid === InputIsValid.Valid ||
        codeState.IsValid === InputIsValid.NotSet;

    async function submitCode() {
        if (loading) {
            return;
        }

        setLoading(true);

        dispatchCode({
            Type: InputState.Input_Blur,
            Value: codeState.Value,
        } as IInputDTO);

        if (codeState.IsValid === InputIsValid.Valid) {
            const success = await SendCode(codeState.Value, authCtx.userData.AspNetUserId,controller);

            if (success) {
                toast.success("Success");
                setSuccess(true);
            }
            else {
                setCodeError(errorText);
            }
        }
        else {
            setCodeError(errorText);
        }

        setLoading(false);
    }

    const codeForm = (
        <LargeButtonLayout>
            <Input
                ref={codeInputRef}
                type={"text"}
                required={true}
                errorMessage={codeError}
                isValid={(success && codeError === undefined) ||
                (codeIsValid && !success && codeError === undefined)}
                placeholder={"Enter your " + GetTvPlatformName(props.platform) + " authentication code here"}
                onChange={codeChangeHandler}
                onBlur={validateCodeHandler}
            />
            <PinkButton onClick={submitCode}>Submit</PinkButton>
        </LargeButtonLayout>
    );

    const unauthenticatedLayout = (
        <InlineButtonLayout>
            <PrimaryText>You need to be a subscriber to access all content on your {GetTvPlatformName(props.platform)} App.</PrimaryText>
            <PrimaryText>If you are new to Ickonic you can <NavLinkPinkButton to={RoutePaths.CreateYourAccount}>create your account</NavLinkPinkButton></PrimaryText>
            <PrimaryText>If you already have a subscription, <NavLinkPinkButton to={RoutePaths.Login}>login</NavLinkPinkButton></PrimaryText>
        </InlineButtonLayout>
    );

    const unsubscribedLayout = (
        <LargeButtonLayout>
            <PrimaryText>You need to be a subscriber to access all content on your {GetTvPlatformName(props.platform)} App.</PrimaryText>
            <NavLinkPinkButton to={RoutePaths.ChoosePlan}>Subscribe Now</NavLinkPinkButton>
        </LargeButtonLayout>
    );

    const successLayout = (
        <LargeButtonLayout>
            Thank you for authenticating, your {GetTvPlatformName(props.platform)} will automatically log you in shortly.
        </LargeButtonLayout>
    );

    function DisplayLayout() {
        if (success) {
            return successLayout;
        }
        else if (isAuthenticated) {
            if (isSubscribed) {
                return codeForm;
            }
            return unsubscribedLayout;
        }
        return unauthenticatedLayout;
    }

    return (
        <Container>
            <Logo src={logo} />
            <ContentContainer>
                <BsTv />
                <Heading>{props.platform} Authentication</Heading>
                {DisplayLayout()}
            </ContentContainer>
        </Container>
    );
}

export default TvAuthenticationScreen;
