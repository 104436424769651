import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";

// Models
import { ISlideFeaturedContent } from "../../Models/Interfaces/ISlideFeaturedContent";

// Components
import ButtonPillUppercase from "../Buttons/ButtonPillUppercase";
import IconArrowCarrot from "../../Assets/SVGs/Icons/ArrowCarrot";
import ButtonsPrevNext from "../Buttons/ButtonsPrevNext";

type SliderFeaturedContentProps = {
    isDarkMode: boolean;
    slides?: ISlideFeaturedContent[];
};

const SliderFeaturedContent: React.FC<SliderFeaturedContentProps> = ({ slides = dummySlides, isDarkMode }) => {
    const
        [currentSlide, setCurrentSlide] = useState(0),
        [timeLeft, setTimeLeft] = useState(10000),
        slideIntervalRef = useRef<NodeJS.Timeout | null>(null),
        timerRef = useRef<NodeJS.Timeout | null>(null),
        sliderTimer = 10000,
        radius = 9,
        circumference = 2 * Math.PI * radius,
        progress = (timeLeft / sliderTimer) * circumference,

        resetSlideInterval = () => {
            if (slideIntervalRef.current) {
                clearInterval(slideIntervalRef.current);
            }

            if (timerRef.current) {
                clearInterval(timerRef.current);
            }

            setTimeLeft(sliderTimer); // Reset timer to 10 seconds

            slideIntervalRef.current = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % (slides?.length || 1));
                setTimeLeft(sliderTimer); // Reset timer to 10 seconds
            }, sliderTimer); // Change slide every 10 seconds
            timerRef.current = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 100);
            }, 100); // Update timer every 100ms
        },

        handleSlideChange = (slideIndex: number) => {
            setCurrentSlide(slideIndex);
            resetSlideInterval();
        },

        handleSwipeLeft = () => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % (slides?.length || 1));
            resetSlideInterval();
        },

        handleSwipeRight = () => {
            setCurrentSlide((prevSlide) => (prevSlide - 1 + (slides?.length || 1)) % (slides?.length || 1));
            resetSlideInterval();
        },

        handleOnClickNext = () => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % (slides?.length || 1));
            resetSlideInterval();
        },

        handleOnClickPrev = () => {
            setCurrentSlide((prevSlide) => (prevSlide - 1 + (slides?.length || 1)) % (slides?.length || 1));
            resetSlideInterval();
        },

        handlers = useSwipeable({
            onSwipedLeft: handleSwipeLeft,
            onSwipedRight: handleSwipeRight,
        });

    useEffect(() => {
        resetSlideInterval();
        return () => {
            if (slideIntervalRef.current) {
                clearInterval(slideIntervalRef.current);
            }

            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [slides]);

    return (
        <div
            {...handlers}
            className={`layout--slider-featured-content ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'}`}
        >
            <div
                className="slider-featured-content__inner"
                style={{
                    transform: `translateX(-${currentSlide * (100 / (slides?.length || 1))}%)`,
                    display: 'flex',
                    width: `${(slides?.length || 1) * 100}%`,
                }}
            >
                {slides && slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`slider-featured-content__slide u-relative_hidden ${currentSlide === index ? 'is-active' : 'is-not-active'}`}
                        style={{
                            background: '#000000',
                            width: `${100 / (slides?.length || 1)}%`,
                            flexShrink: 0,
                        }}
                    >
                        <div
                            className="u-full_cover_absolute"
                            style={{background: `url(${slide.bgImage}) center/cover no-repeat`, opacity: '0.3'}}
                        />
                        <div className="slider-featured-content__content">
                            <h1><span>{slide.heading}</span></h1>
                            <h2><span>{slide.subHeading}</span></h2>
                            <div className="slider-featured-content__button-wrapper u-relative_hidden">
                                <ButtonPillUppercase label={slide.buttonText} className="" link=""/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {slides && (
                <div className="slider-featured-content__dots">
                    {slides.map((slide, index) => (
                        <div
                            key={index}
                            onClick={ () => handleSlideChange(index) }
                            className={`slider-featured-content__dot-control ${currentSlide === index ? 'is-active' : 'is-not-active'}`}
                        />
                    ))}
                </div>
            )}

            <ButtonsPrevNext
                onPrev={handleOnClickPrev}
                onNext={handleOnClickNext}
                currentIndex={currentSlide}
                parentClass="slider-featured-content__controls"
            />

            <svg className="slider-featured-content__radial-timer" width="22" height="22" viewBox="0 0 22 22">
                <circle
                    stroke="white"
                    fill="transparent"
                    strokeWidth="4"
                    strokeDasharray={circumference}
                    strokeDashoffset={circumference - progress}
                    r={9} // Radius adjusted to fit within the 22x22 size
                    cx="11"
                    cy="11"
                />
            </svg>

            <div className="slider-featured-content__shadow u-full_cover_absolute" />
        </div>
    );
};

const dummySlides = [
    {
        heading: "The Trigger",
        subHeading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eget diam maximus",
        bgImage: "/placeholder-images/pexels-pixabay-247599.jpg",
        buttonText: 'Get Notified',
    },
    {
        heading: "The Dream",
        subHeading: "Interdum et malesuada fames ac ante ipsum primis in faucibus.",
        bgImage: "/placeholder-images/pexels-bri-schneiter-28802-346529.jpg",
        buttonText: "Watch Now",
    },
    {
        heading: "Divine Intervention",
        subHeading: "Vivamus semper consectetur orci non fringilla. Fusce odio justo, convallis ut tortor id, suscipit condimentum ipsum.",
        bgImage: "/placeholder-images/pexels-souvenirpixels-417074.jpg",
        buttonText: "Watch Now",
    },
];

export default SliderFeaturedContent;
