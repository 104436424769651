import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// Assets
import IconArrowSmall from "../../Assets/SVGs/Icons/ArrowSmall";

// Constants
import {Colours} from "../../Constants/Colours";

interface FeatureItem {
    title: string;
    body: string;
    icon: React.ReactElement;
    timeframe: string;
}

const Roadmap = styled.div`
    .layout__inner {
        padding: 2rem 0;
        max-width: calc(1600rem/16);
        margin: 0 auto;
    }

    .layout__scroll-wrapper {
        overflow-x: auto;
        display: flex;
        ::-webkit-scrollbar-track {
            border-radius: calc(8rem/16);
            background-color: rgba(255, 255, 255, 0);
        }

        ::-webkit-scrollbar {
            width: calc(8rem/16);
            background-color: #ffffff00;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: calc(8rem/16);
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #555;
        }
    }

    .layout__instructions {
        text-align: center;
        font-size: calc(14rem/16);
        text-transform: uppercase;
        width: 100%;
        letter-spacing: calc(2rem/16);
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            height: calc(24rem/16);
            width: calc(24rem/16);
            animation: translateLeft 2s infinite ease-in-out;
            svg {
                *
                    fill: ${ Colours.Accent };
                }
            }
        }

        .is-rotated {
            animation: translateRight 2s infinite ease-in-out !important;
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .layout__time-frame {
        > h4 {
            padding: calc(5rem/16) calc(10rem/16) calc(4rem/16) calc(10rem/16);
            box-shadow: inset 0 0 0 calc(1rem/16) rgba(255,255,255,0.2);
            text-transform: uppercase;
            display: inline-block;
            font-family: "Manrope";
            letter-spacing: calc(2rem/16);
        }

        .layout__features {
            display: flex;
            .layout__item {
                max-width: calc(400rem/16);
                margin: 1rem 0 2rem 0;
                padding: 1rem;
                box-shadow: inset calc(1rem/16) 0 0 0 rgba(255,255,255,0.2);
                //height: calc(210rem/16);
                display: flex;
                align-items: flex-start;
                .layout__icon {
                    width: calc(50rem/16);
                    height: calc(50rem/16);
                    padding: calc(10rem/16);
                    border-radius: 50%;
                    margin-right: calc(16rem/16);
                    box-shadow: inset 0 0 0 calc(2rem/16) ${ Colours.Accent };
                }

                svg {
                    width: 100%;
                    height: 100%;
                }

                .layout__content {
                    width: calc(100% - (76rem/16));
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    .layout__heading {
                        min-height: calc(48rem/16);
                        display: flex;
                        align-items: center;
                        line-height: 1.3;
                        h3 {
                            text-transform: uppercase;
                            font-family: "Outfit";
                            font-weight: bold;
                            font-size: calc(20rem/16);
                            letter-spacing: calc(2rem/16);
                            margin: 0;
                        }
                    }


                    p {
                        font-family: "Rubik";
                        font-size: calc(14rem/16);
                    }
                }
            }
        }
    }
`;

const FeatureRoadmap = (props: { features: FeatureItem[] }) => {
    // Extract unique timeframes using Set
    const
        roadmapRef = useRef<HTMLDivElement>(null),
        [roadmapWidth, setRoadmapWidth] = useState<number>(0),
        uniqueTimeframes = Array.from(new Set(props.features.map(feature => feature.timeframe)));

    useEffect(() => {
        // Dynamically get the width of the layout--feature-roadmap
        const updateWidth = () => {
            if (roadmapRef.current) {
                setRoadmapWidth(roadmapRef.current.offsetWidth);
            }
        };

        // Set width on mount and update on window resize
        updateWidth();
        window.addEventListener('resize', updateWidth);

        // Cleanup listener to prevent memory leaks
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    return (
        <Roadmap className="layout layout--feature-roadmap">
            <span className="layout__instructions">
                <div>{IconArrowSmall()}</div>
                Swipe / Scroll Side to Side
                <div className="is-rotated">{IconArrowSmall()}</div>
            </span>

            <div className="layout__inner" ref={roadmapRef}>
                <div className="layout__scroll-wrapper">
                    {uniqueTimeframes.map((timeframe, index) => (
                        <div className="layout__time-frame" key={index}>
                            <h4>{timeframe}</h4>

                            <div className="layout__features">
                                {
                                    props.features
                                        .filter(feature => feature.timeframe === timeframe)
                                        .map((feature, featureIndex) => (
                                            <div
                                                key={featureIndex}
                                                style={{ width: `${roadmapWidth}px` }}
                                                className="layout__item"
                                            >
                                                <div className="layout__icon">
                                                    {feature.icon}
                                                </div>

                                                <div className="layout__content">
                                                    <div className="layout__heading">
                                                        <h3>{feature.title}</h3>
                                                    </div>

                                                    <p>{feature.body}</p>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Roadmap>
    );
};

export default FeatureRoadmap;
