import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AddComment } from "../../../Api/VideoComment";
import { ContainsBadWords, IsCommentValid } from "../../../Helpers/Utility";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import IVideoCommentDTO from "../../../Models/DTOs/IVideoCommentDTO";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import InputWithButton from "../Inputs/InputWithButton";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

function CommentInput(props: {
    videoId: number;
    replyTo?: IVideoCommentDTO;
    edit?: IVideoCommentDTO;
    placeholder: string;
    UpdateComments: (videoId: number) => Promise<void>;
    disableInput?: boolean;
    disable?: boolean;
}) {
    const CommentInput = useRef<HTMLInputElement | null>(null);
    const [formIsValid, setFormIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("Error");
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();

    function GetValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setErrorMessage("Comment must contain text.");
            return InputIsValid.Invalid;
        }

        if (!IsCommentValid(text)) {
            setErrorMessage("Comment cannot contain special characters or URLs.");
            return InputIsValid.Invalid;
        }

        if (ContainsBadWords(text)) {
            setErrorMessage("Comment contains curse words. Please remove before commenting.");
            return InputIsValid.Invalid;
        }

        return InputIsValid.Valid;
    }

    function commentReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    const [commentState, dispatchComment] = useReducer(commentReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const commentIsValid =
        commentState.IsValid === InputIsValid.Valid || commentState.IsValid === InputIsValid.NotSet;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(commentIsValid);
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [commentIsValid]);

    // Is used to switch value in input based on when editing or creating a new comment.
    useEffect(() => {
        if (CommentInput.current === null) {
            return;
        }

        if (props.edit !== undefined) {
            dispatchComment({
                Type: InputState.Not_Set,
                Value: props.edit.Text,
                IsValid: InputIsValid.Invalid,
            } as IInputDTO);
        } else {
            dispatchComment({
                Type: InputState.Not_Set,
                Value: "",
                IsValid: InputIsValid.NotSet,
            } as IInputDTO);
        }
    }, [props.edit]);

    const commentChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatchComment({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    };

    async function SubmitComment() {
        //Picks the master comment id of reply to if one exists or uses reply to id. If undefined pick then returns undefined making this comment a master comment.
        const masterCommentId =
            props.edit !== undefined
                ? props.edit.MasterCommentId
                : props.replyTo !== undefined && props.replyTo.MasterCommentId != null
                ? props.replyTo.MasterCommentId
                : props.replyTo !== undefined && props.replyTo.MasterCommentId === null
                ? props.replyTo.Id
                : undefined;

        const replyId =
            props.edit !== undefined
                ? props.edit.ReplyId
                : props.replyTo !== undefined
                ? props.replyTo.Id
                : undefined;

        const newComment = {
            Id: props.edit !== undefined && props.edit.Id > 0 ? props.edit.Id : 0,
            Text: commentState.Value.trim(),
            ReplyId: replyId,
            AspNetUserId: authCtx?.userData.AspNetUserId,
            MasterCommentId: masterCommentId,
        } as IVideoCommentDTO;

        await AddComment(newComment, props.videoId, controller);

        //Resets input
        dispatchComment({
            Value: "",
            IsValid: InputIsValid.NotSet,
        } as IInputDTO);

        await props.UpdateComments(props.videoId);
    }

    async function SubmitHandler(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        dispatchComment({
            Type: InputState.Input_Blur,
            Value: commentState.Value,
        } as IInputDTO);

        if (formIsValid && commentIsValid) {
            await SubmitComment();
        } else if (!commentIsValid || commentState.IsValid === InputIsValid.NotSet) {
            CommentInput.current?.focus();
        }
    }

    return (
        <InputWithButton
            ref={CommentInput}
            type={"text"}
            value={commentState.Value}
            placeholder={props.placeholder}
            onBlur={() => {}}
            onChange={commentChangeHandler}
            isValid={formIsValid}
            onSubmit={SubmitHandler}
            disabled={!(formIsValid && commentState.IsValid !== InputIsValid.NotSet)}
            disableInput={props.disableInput}
            errorMessage={errorMessage}
        >
            Comment
        </InputWithButton>
    );
}

export default CommentInput;
