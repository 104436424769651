export const IconMobilePhone = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M19 2a2 2 0 00-2-2H7a2 2 0 00-2 2v20a2 2 0 002 2h10a2 2 0 002-2V2zm-8.5 0h3a.5.5 0 010 1h-3a.5.5 0 010-1zM12 22a1 1 0 110-2 1 1 0 010 2zm5-3H7V4.976h10V19z"></path>
        </svg>
    )
};

export default IconMobilePhone;
