import React from "react";

const IconRefreshCheckmark = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={props?.iconFill}
                fillRule="nonzero"
                d="M2.164 10.201c.055-.298.393-.734.934-.59a.752.752 0 01.543.86C3.564 11 3.5 11.324 3.5 12c0 4.47 3.601 8.495 8.502 8.495 2.173 0 4.241-.84 5.792-2.284l-1.251-.341a.751.751 0 01.389-1.449l3.225.864a.752.752 0 01.53.919l-.875 3.241a.75.75 0 11-1.449-.388l.477-1.77a10.028 10.028 0 01-6.838 2.708c-5.849 0-9.968-4.8-10.002-9.93a9.987 9.987 0 01.164-1.864zm5.396 2.857l2.924 2.503c.142.128.321.19.499.19a.746.746 0 00.552-.242L16.488 10a.747.747 0 00-.552-1.249.751.751 0 00-.554.243l-4.453 4.962-2.371-2.011a.746.746 0 10-.998 1.113zm14.276.743c-.054.298-.392.734-.933.59a.75.75 0 01-.543-.86c.068-.48.139-.848.139-1.53 0-4.479-3.609-8.495-8.5-8.495a8.526 8.526 0 00-5.794 2.285l1.251.341a.75.75 0 11-.388 1.448l-3.225-.864a.75.75 0 01-.53-.918l.875-3.241a.75.75 0 111.449.388l-.478 1.769a10.034 10.034 0 016.839-2.708C17.863 2.006 22 6.836 22 12.001a9.97 9.97 0 01-.164 1.8z"
            ></path>
        </svg>
    )
};

export default IconRefreshCheckmark;
