import { useContext, useState } from "react";
import BrowseLoader from "./UI/PageLoaders/BrowseLoader";

// Context
import {UserAuthenticationContext} from "../Context/UserAuthenticationContext";

function AuthScreenRedirect(props: { SubscribedScreen: JSX.Element; FreeScreen: JSX.Element }) {
    const
        authCtx = useContext(UserAuthenticationContext),
        [isLoaded, setIsLoaded] = useState<boolean>(false);

    if (!isLoaded) {
        return (
            <BrowseLoader />
        )
    }

    return authCtx?.userData.AspNetUserId !== undefined && authCtx?.userData.AspNetUserId !== '' ? props.SubscribedScreen : props.FreeScreen;
}

export default AuthScreenRedirect;
