import React from "react";
import {NavLink} from "react-router-dom";

interface ButtonPillUppercaseProps {
    label: string,
    className: string,
    link: string,
    openInNewWindow?: boolean,
    callbackFunction?: () => void,
    onClick?: () => void,
}

const ButtonPillUppercase: React.FC<ButtonPillUppercaseProps> = ({
    label = 'Click Here',
    className = '',
    link = '',
    callbackFunction = () => {} // Provide a default no-op function if undefined
}) => {
    return link === "" ? (
        <button className={`ickonic-button ickonic-button__pull-uppercase button ${className}`} onClick={callbackFunction}>
            {label}
        </button>
    ) : (
        <NavLink to={link} className={`ickonic-button ickonic-button__pull-uppercase button ${className}`} onClick={callbackFunction}>
            {label}
        </NavLink>
    );
};

export default ButtonPillUppercase;
