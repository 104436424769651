import React, { Suspense, useEffect, useRef, useState, useContext } from "react";
import { SetTitle } from "../../Helpers/Utility";
import { HeadingType } from "../../Models/Enums/HeadingType";
import { Colours } from "../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import { FaUsers } from "react-icons/fa";
import { HelpTopicType } from "../../Models/Enums/HelpTopicType";
import { IAccordionItem } from "../../Models/IAccordionItem";
import IHelpTopicDTO from "../../Models/DTOs/IHelpTopicDTO";
import styled from "styled-components";
import Heading from "../../Components/UI/Text/Heading";
import Modal from '../../Components/UI/Modals/Modal';
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import PinkButton from '../../Components/UI/Buttons/PinkButton';
import Accordion from "../../Components/UI/Accordions/Accordion";
import PrimaryText from "../../Components/UI/Text/PrimaryText";
import ContactUs from "../../Components/UI/Forms/ContactUs";
import GetHelpTopics from "../../Api/HelpTopics";
import { AxiosError } from "axios";
import { IUserDetails } from "../../Models/IUserDetails";
import { Fonts } from "../../Constants/Fonts";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

const Container = styled.section`
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    width: 95%;

    @media only screen and (min-width: 1200px) {
        max-width: ${ResponsiveBreakpoints.ContainerDesktopWidth + 'px'};
    }
`;

const Header = styled.div`
    text-align: center;

    h1 {
        font-size: 38px ;
        margin-bottom: 0.5rem;
    }

    h6 {
        margin-top: 0;
        font-size: 25px;
        a {
            color: ${Colours.IckonicPink};
            text-decoration: none;
        }
    }
`;

const HelpCentreBox = styled.div`
    width: 100%;
    border-radius: 7px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 25px;
    background: ${Colours.Tertiary};
    position: relative;
    color: ${Colours.Text};

    svg {
        font-size: 60px;
    }

    h1 {
        margin: 5px 0 0 0;
    }

    p {
        margin: 5px 0 5px 0;
        font-size: 12pt;
    }
`;

const PopupTopicsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: row;
    width: 100%;
    padding-top: 45px;
    padding-bottom: 45px;

    button {
        @media only screen and (max-width: 600px) {
            flex-basis: 100%;
            padding-right: 1vh;
            padding-left: 1vh;
            width: 100%;
        }

        flex-basis:30%;
        padding-right: 1vh;
        padding-left: 1vh;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        white-space: nowrap;
        width: 100%;

        h4 {
            margin:0
        }
    }
`;

const ModalContainer = styled.div`
    font-family: ${ Fonts.Primary };
    color: ${Colours.Text};

    figure {
        @media only screen and (max-width: 1200px) {
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            display: flex!important;
            flex-direction: column!important;
            overflow: hidden;
        }

        table {
            background-color:${Colours.SecondaryHighlight}!important;
            width: 100%;
            table-layout:fixed;

            td {
                padding:10px;
                background-color: #262626 !important;
            }

            a {
                color: ${Colours.IckonicPink};
                text-decoration: none;
            }
        }
    }
`;

const AccordionContainer = styled.div`
    margin-bottom: 40px;

    figure {
        @media only screen and (max-width: 1200px) {
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }

        table {
            background-color:${Colours.SecondaryHighlight}!important;
            width: 100%;
            table-layout:fixed;

            td {
                background-color: #262626 !important;
                padding-left: 20px;
                padding-right: 20px;
            }

            a {
                color: ${Colours.IckonicPink};
                text-decoration: none;
                word-break: break-all;
            }
        }
    }
`;

const ContactFormContainer = styled.div`
    margin-bottom: 40px;
`;

function HelpCentreScreen() {
    const controller = new AbortController();
    const isLoaded = useRef<boolean>(false);
    const authCtx = useContext(UserAuthenticationContext);

    const [popupTopics, setPopupTopics] = useState<IHelpTopicDTO[]>([]);
    const [faqTopics, setFaqTopics] = useState<IAccordionItem[]>([]);
    const [displayPopupTopic, setDisplayPopUpTopic] = useState<boolean>(false);
    const [popupTopicTitle, setPopupTopicTitle] = useState<string>("");
    const [popUpTopicContent, setPopUpTopicContent] = useState<string | JSX.Element | JSX.Element[]>();

    useEffect(() => {
        if (isLoaded.current) {
            return;
        }

        SetTitle("Help Centre");

        async function LoadData() {
            await LoadHelpTopics();
        }

        LoadData();
        isLoaded.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function LoadHelpTopics() {
        const result = await GetHelpTopics(controller);

        if (result === null || result instanceof AxiosError) {
            return;
        }

        setPopupTopics(result.filter(x => x.TopicType === HelpTopicType.PopUp));
        setFaqTopics(result.filter(x => x.TopicType === HelpTopicType.FAQ).map<IAccordionItem>((faq) => {
            return { Title: faq.Title, Content: parse(DOMPurify.sanitize(faq.Content)) };
        }));
    }

    function PopUpTopicButtonClicked(topic: IHelpTopicDTO) {
        setPopupTopicTitle(topic.Title);

        const content = parse(DOMPurify.sanitize(topic.Content));
        setPopUpTopicContent(content);
        setDisplayPopUpTopic(true);
    }

    // If user data is not loaded yet, you might show a fallback
    // This depends on how your RootLayout or initialization is done.
    // For now, just ensure authCtx.userData exists.
    const userDetails = authCtx.userData;

    return (
        <Container>
            <Header>
                <Heading>Questions? Look Here</Heading>
                <Heading type={HeadingType.H6}>
                    Can't find an answer? Email <a href="mailto:support@ickonic.com">support@ickonic.com</a>
                </Heading>
            </Header>
            <HelpCentreBox>
                <FaUsers />
                <Heading>Help Centre</Heading>
            </HelpCentreBox>
            <PopupTopicsContainer>
                {popupTopics.map((topic, index) => {
                    return (
                        <React.Fragment key={index}>
                            <PinkButton
                                type="button"
                                onClick={() => PopUpTopicButtonClicked(topic)}>
                                <Heading type={HeadingType.H4}>{topic.Title}</Heading>
                            </PinkButton>
                        </React.Fragment>
                    )
                })}
            </PopupTopicsContainer>
            <AccordionContainer>
                <Accordion data={faqTopics} />
            </AccordionContainer>
            <ContactFormContainer>
                <HelpCentreBox>
                    <Heading>Still Having trouble?</Heading>
                    <PrimaryText>Fill out the contact form below and we'll be back in touch as soon as possible.</PrimaryText>
                    {/* Pass userDetails directly from authCtx */}
                    <ContactUs userDetails={userDetails} />
                </HelpCentreBox>
            </ContactFormContainer>

            <ModalContainer>
                <Modal
                    display={displayPopupTopic}
                    setDisplay={setDisplayPopUpTopic}
                    title={popupTopicTitle}>
                    {popUpTopicContent}
                </Modal>
            </ModalContainer>
        </Container>
    );
}

export default HelpCentreScreen;
