import React from "react";

const IconTranslation = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M21 10h-8a3 3 0 00-3 3v8a3 3 0 003 3h8a3 3 0 003-3v-8a3 3 0 00-3-3zm-1.865 11l-.66-1.846h-3L14.812 21h-1.66l3.041-8h1.602l3.053 8h-1.713zm-2.153-6.137l1.051 3.018H15.93l1.052-3.018zM5.145 7.886c.038.581.104 1.116.194 1.593-1.224.224-1.274-.984-.194-1.593zM14 3v5h-1c-.743 0-1.442.173-2.076.465-.196-.806-.783-1.53-1.679-1.964.077-.298.123-.522.167-.744l-1.35-.262c-.023.113-.063.328-.136.614a5.876 5.876 0 00-1.431-.025 17.1 17.1 0 01.06-.963h1.507V3.842H6.736c.065-.326.121-.521.176-.705l-1.317-.396a8.98 8.98 0 00-.259 1.101H3.832V5.12h1.341c-.036.414-.057.842-.064 1.275C3.629 6.939 3 7.976 3 8.916c0 1.11.876 2.086 2.261 1.96 1.72-.156 2.869-1.456 3.542-3.053.885.536.838 1.161.773 1.552A4.974 4.974 0 008 13v1H3a3 3 0 01-3-3V3a3 3 0 013-3h8a3 3 0 013 3zM6.5 7.467a4.79 4.79 0 01.969-.026c-.214.476-.496.948-.855 1.319-.055-.396-.093-.83-.114-1.293z">
            </path>
        </svg>
    )
};

export default IconTranslation;
