import React from "react";

const IconShare = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M5 7a5 5 0 11-.001 10.001A5 5 0 015 7zm11.122 12.065A3.946 3.946 0 0016 20a4 4 0 104-4 3.974 3.974 0 00-2.935 1.301l-5.488-2.927a6.973 6.973 0 01-.943 1.764l5.488 2.927zM24 4a4 4 0 00-8 0c0 .324.049.634.122.935l-5.488 2.927c.395.535.713 1.127.943 1.764l5.488-2.927A3.974 3.974 0 0020 8a4 4 0 004-4z"> </path>
        </svg>
    )
};

export default IconShare;
