import { BsFacebook, BsTwitter, BsLinkedin, BsReddit } from "react-icons/bs";
import { ReactComponent as Minds } from '../../Assets/Images/Logos/logo-minds.svg';
import { title } from "process";
import { toast } from "react-toastify";
import styled from "styled-components";

// Models
import { ContentType } from "../../Models/Enums/ContentType";

// Helpers
import { ContentTypeToString, GetArticleShareUrl } from "../../Helpers/Utility";

// Constants
import { Ease } from "../../Constants/EasingCurves";
import { Colours } from "../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";

// Components
import PrimaryText from "./Text/PrimaryText";

// Assets
import IconCopyLink from "../../Assets/SVGs/Icons/CopyLink";
import IconEmailSharpCorners from "../../Assets/SVGs/Icons/EmailSharpCorners";
import IconShare from "../../Assets/SVGs/Icons/Share";

const SocialsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #050505;
    padding: 7px 6px 7px 12px;
    border-radius: 25px;
    svg {
        width: 100%;
        height: 100%;
        * {
            transition: fill .25s ${ Ease.Smooth };
        }
    }

    > p {
        display: none;
        margin: 0 10px 0 0;
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            display: block;
        }
    }

    > a,
    > button {
        width: 37px;
        height: 37px;
        cursor: pointer;
        margin: 0;
        padding: 7px;
        border-radius: 50%;
        transition: background .15s ${ Ease.Smooth };
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
            margin: 0 5px 0 0;
        }

        &:hover,
        &:focus {
            background: rgba(45,45,45);
            svg {
                * {
                    fill: white;
                }
            }
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
        padding: 7px 2px 7px 12px;
    }
`;

const Facebook = styled(BsFacebook)`
    color: #bcbcbc;
    padding: 1px;
`;

const Twitter = styled(BsTwitter)`
    color: #bcbcbc;
`;

const LinkedIn = styled(BsLinkedin)`
    color: #bcbcbc;
    padding: 2px;
`;

const Reddit = styled(BsReddit)`
    color: #bcbcbc;
`;

const IconMinds = styled(Minds)`
    * {
        fill: #bcbcbc;
    }
`;

const Email = styled.a`
    svg {
        width: 100%;
        height: 100%;
    }

    * {
        fill: #bcbcbc;
    }
`;

const Copy = styled.button`
    background: none;
    border: none;
    padding: 8px !important;
    svg {
        width: 100%;
        height: 100%;
    }

    * {
        fill: #bcbcbc;
    }
`;

const Share = styled.div`
    width: 30px;
    height: 30px;
    padding: 6px;
    * {
        fill: ${ Colours.IckonicPinkHighlight };
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        margin: 0 7px 0 0;
        * {
            fill: ${ Colours.IckonicPink };
        }
    }
`;

function Socials(props: { displayText: boolean; title: string, contentType: ContentType | string, articleUrlTitle?: string}) {

    // Share URL
    const shareUrl = (props.contentType === ContentType.Article && props.articleUrlTitle !== null) && props.articleUrlTitle !== undefined ?
    GetArticleShareUrl(props.articleUrlTitle) : window.location.href;

    let contentType : string;
    if (typeof props.contentType === "string") {
        contentType = props.contentType;
    }
    else {
        contentType = ContentTypeToString(props.contentType);
    }

    return (
        <SocialsContainer>

            <Share>
                <IconShare />
            </Share>

            {props.displayText ? <PrimaryText>Share </PrimaryText> : null}

            <a href={"https://www.facebook.com/sharer/sharer.php?u="
                + shareUrl + "&t=" + title}
                title="Share on Facebook"
                id="fb_share"
                target="_blank"
                rel="noreferrer nofollow">
                <Facebook />
            </a>

            <a
                href={
                    "https://twitter.com/intent/tweet?u=" +
                    shareUrl +
                    "&text=check out this " + contentType + " by @ickonic on " +
                    shareUrl
                }
                title="Share on Twitter"
                target="_blank"
                rel="noreferrer nofollow"
            >
                <Twitter />
            </a>
            <a
                href={"https://www.linkedin.com/sharing/share-offsite/?url=" + shareUrl}
                title="Share on LinkedIn"
                target="_blank"
                rel="noreferrer nofollow"
            >
                <LinkedIn />
            </a>

            <a
                href={"https://reddit.com/submit?url=" + shareUrl + "&title=" + props.title}
                title="Share on reddit"
                target="_blank"
                rel="noreferrer nofollow"
            >
                <Reddit />
            </a>

            <a
                href={
                    "https://www.minds.com/newsfeed/subscriptions/latest?intentUrl=Check out this content by @ickonicmediagroup at" +
                    shareUrl
                }
                title="Share on Minds"
                target="_blank"
                rel="noreferrer nofollow"
                style={ { padding: '4px' } }
            >
                <IconMinds />
            </a>

            <Email
                href={
                    "mailto:?subject=" +
                    props.title +
                    "&body=Check out " +
                    props.title +
                    " from Ickonic at " +
                    shareUrl
                }
                title="Share in an E-mail"
                target="_blank"
                rel="noreferrer nofollow"
            >
                <IconEmailSharpCorners />
            </Email>

            <Copy
                title="Copy to clipboard"
                onClick={(e) => {
                    e.preventDefault();
                    toast("The URL has been copied in your clipboard.");
                    navigator.clipboard.writeText(shareUrl);
                }}
            >
                <IconCopyLink />
            </Copy>
        </SocialsContainer>
    );
}

export default Socials;
