import { useContext, useEffect, useState } from "react";
import { HeadingType } from "../../Models/Enums/HeadingType";
import { Colours } from "../../Constants/Colours";
import styled from "styled-components";
import AccountSection from "../../Components/UI/Account/AccountSection";
import PinkButton from "../../Components/UI/Buttons/PinkButton";
import PrimaryText from "../../Components/UI/Text/PrimaryText";
import Heading from "../../Components/UI/Text/Heading";
import StripeCard from "../../Components/UI/StripeCard";
import { PaymentMethod } from "@stripe/stripe-js";
import { AddPaymentMethod } from "../../Api/Stripe";
import { DisconnectFromAllDevices, GetYourAccountDTO } from "../../Api/Account";
import { IYourAccountDTO } from "../../Models/DTOs/IYourAccountDTO";
import {
    CanUpgrade,
    GetAccessLevelTitle,
    GetPlanPeriod,
    GetPlanType,
    GetPlanSubText,
    GetRenewStatusTitle,
    PlanInterval,
    SetLoginAfterToNow,
    SetTitle,
    SymbolFromCurrency,
} from "../../Helpers/Utility";
import EditPerson from "../../Components/UI/Account/EditPerson";
import EditEmail from "../../Components/UI/Account/EditEmail";
import EditCommercialEmails from "../../Components/UI/Account/EditCommericalEmails";
import ChangePasswordForm from "../../Components/UI/Account/ChangePasswordForm";
import GreyButton from "../../Components/UI/Buttons/GreyButton";
import UpgradeModal from "../../Components/UI/Modals/UpgradeModal";
import CancelModal from "../../Components/UI/Modals/CancelModal";
import { SubscriptionStatus } from "../../Models/Enums/SubscriptionStatus";
import { IUserDetails } from "../../Models/IUserDetails";
import TextLoader from "../../Components/UI/PageLoaders/TextLoader";
import { RoutePaths } from "../../Constants/RoutePaths";
import { ISubscriptionDTO } from "../../Models/DTOs/ISubscriptionDTO";
import { AxiosError } from "axios";
import EditDailyNewsletter from "../../Components/UI/Account/EditDailyNewsletter";
import moment from "moment";
import { toast } from "react-toastify";
import { Fonts } from "../../Constants/Fonts";
import NavLinkPinkButton from "../../Components/UI/Buttons/NavLinkPinkButton";
import PopUp from "../../Components/UI/Modals/PopUp";
import AccountRow from "../../Components/UI/Account/AccountRow";
import PinkTextButton from "../../Components/UI/Buttons/PinkTextButton";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import Card from "../../Components/UI/Card";
import { NeedsRenew } from "../../Helpers/UserUtility";
import { InvoiceStatus } from "../../Models/Enums/InvoiceStatus";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { PlanPeriod } from "../../Models/Enums/PlanPeriod";

const Container = styled.section`
    padding: 1rem;
    button {
        margin: 0 0 1rem 0;
    }
`;

const MainHeading = styled(Heading)`
    text-align: center;
    margin: 0;
    padding: 2rem 1rem;
    background: ${Colours.Secondary};
    @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}rem/16)) {
        font-size: 25pt;
    }
`;

const ContentSpit = styled.div`
    > h3 {
        margin: 0 0 calc(8rem/16) 0;
    }
`;

const ContentContainer = styled.div``;

const StripeCardContainer = styled.div`
    max-width: calc(600rem/16);
`;

const PlanTable = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const PlanElem = styled.div`
    padding: 0 0 calc(10rem/16) 0;
    width: 100%;
    font-family: ${Fonts.Primary};
    h4 {
        margin: 0;
        color: ${Colours.TextDarker};
        font-size: 12pt;
    }

    p {
        font-size: 12pt;
        color: ${Colours.TextBright};
        margin: calc(5rem/16) 0 0 0;
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
        flex:1;
        min-width: 30%;
        padding: 0 calc(25rem/16) calc(25rem/16) 0;
    }
`;

async function GetYourAccountDetails(userDetails: IUserDetails, controller: AbortController) {
    const result = await GetYourAccountDTO(
        userDetails.AspNetUserId,
        userDetails.Access_Token,
        controller
    );

    if (result === null || result instanceof AxiosError) {
        return null;
    }

    return result;
}

function AccountScreen() {
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();

    const [displayUpgrade, setDisplayUpgrade] = useState<boolean>(false);
    const [displayCancel, setDisplayCancel] = useState<boolean>(false);
    const [upgradeSuccess, setUpgradeSuccess] = useState<boolean>(false);
    const [accountData, setAccountData] = useState<IYourAccountDTO | null | undefined>(undefined);

    const currentPlan = GetPlanType(
        authCtx.userData.ContentAccessType,
        authCtx.userData.Period
    );

    const currentPlanTitle =
        currentPlan !== undefined ? GetPlanPeriod(currentPlan) + "ly Plan" : "";

    const currencySymbol = SymbolFromCurrency(authCtx.userData.Currency);

    useEffect(() => {
        SetTitle("Account");
    }, []);

    useEffect(() => {
        // If user is not authenticated, redirect to home or show a loader
        if (!authCtx.userData || !authCtx.userData.AspNetUserId) {
            // Not authenticated: redirect or show loader as needed
            // For example:
            window.location.href = RoutePaths.Root;
            return;
        }

        // Fetch account details if user is authenticated
        async function fetchData() {
            const result = await GetYourAccountDetails(authCtx.userData, controller);
            setAccountData(result);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.userData]);

    useEffect(() => {
        async function refreshAccount() {
            const result = await GetYourAccountDetails(authCtx.userData, controller);
            setAccountData(result);
            setUpgradeSuccess(false);
        }

        if (upgradeSuccess && authCtx.userData.AspNetUserId) {
            refreshAccount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upgradeSuccess]);

    if (!authCtx.userData || !authCtx.userData.AspNetUserId) {
        // User data not loaded yet, show loader
        return <TextLoader />;
    }

    if (accountData === undefined) {
        // Account data is loading
        return <TextLoader />;
    }

    const canUpgrade = CanUpgrade(authCtx.userData);
    const disableCancel =
        accountData === null ||
        accountData === undefined ||
        accountData.LatestInvoiceStatus !== InvoiceStatus.Paid ||
        authCtx.userData.SubscriptionStatus === SubscriptionStatus.Cancelled ||
        authCtx.userData.SubscriptionStatus === SubscriptionStatus.None ||
        authCtx.userData.SubscriptionStatus === SubscriptionStatus.Incomplete_Expired;

    async function AddStripePaymentMethod(paymentMethod: PaymentMethod) {
        const result = await AddPaymentMethod(
            authCtx.userData.AspNetUserId,
            paymentMethod.id,
            controller
        );

        if (result instanceof AxiosError) {
            return false;
        }

        authCtx.refreshUserData(authCtx.userData.Access_Token);
        return result;
    }

    async function DisconnectOtherDevices() {
        let result = await DisconnectFromAllDevices(authCtx.userData.Access_Token, controller);
        if (result) {
            toast.success("Success! Your other website sessions will be disconnected.");
            SetLoginAfterToNow();
        }
    }

    return (
        <>
            <MainHeading>
                Account Settings
            </MainHeading>

            <Container>
                <AccountSection
                    title="Your Details"
                >
                    <AccountRow>
                        <PrimaryText>
                            {authCtx.userData.Person?.FirstName}
                        </PrimaryText>

                        <PinkTextButton onClick={() => { document.dispatchEvent(new CustomEvent('editPerson')) }} disabled={false}>
                            Edit Name
                        </PinkTextButton>
                    </AccountRow>

                    <AccountRow>
                        <PrimaryText>
                            {authCtx.userData.Email}
                        </PrimaryText>

                        <PinkTextButton onClick={() => { document.dispatchEvent(new CustomEvent('editEmail')) }} disabled={false}>
                            Edit Email
                        </PinkTextButton>
                    </AccountRow>

                    <AccountRow>
                        <PrimaryText>
                            Password: **********
                        </PrimaryText>

                        <PinkTextButton onClick={() => { document.dispatchEvent(new CustomEvent('changePassword')) }} disabled={false}>
                            Change Password
                        </PinkTextButton>
                    </AccountRow>
                </AccountSection>

                <AccountSection
                    title="Email Preferenes"
                >
                    <EditCommercialEmails
                        specialOffers={
                            authCtx.userData.AllowCommericalEmails !== undefined
                                ? authCtx.userData.AllowCommericalEmails
                                : false
                        }
                    />

                    <EditDailyNewsletter value={
                        authCtx.userData.AllowDailyNewsletter !== undefined
                            ? authCtx.userData.AllowDailyNewsletter
                            : false}
                    />
                </AccountSection>

                <AccountSection
                    title={"Current Plan"}
                    headingSibling={
                        authCtx.userData.AccessLevel >= 5 && !NeedsRenew(authCtx.userData) ?
                            null
                            :
                            (
                                canUpgrade ?
                                    (
                                        <PinkButton
                                            onClick={() => {
                                                setDisplayUpgrade(true);
                                            }}
                                        >
                                            Upgrade
                                        </PinkButton>
                                    )
                                    :
                                    (
                                        <NavLinkPinkButton
                                            to={RoutePaths.ChoosePlan}
                                        >
                                            Choose Plan
                                        </NavLinkPinkButton>
                                    )
                            )
                    }
                >
                    <ContentContainer>
                        <ContentSpit>
                            <Heading type={HeadingType.H3}>
                                {currentPlanTitle}
                            </Heading>

                            <PlanTable>
                                <PlanElem>
                                    <h4>Number of active devices:</h4>
                                    <p>
                                        {GetAccessLevelTitle(
                                            authCtx.userData.AccessLevel
                                        )}
                                    </p>
                                </PlanElem>
                                <PlanElem>
                                    <h4>Auto renewal: </h4>
                                    <p>
                                        {GetRenewStatusTitle(
                                            authCtx.userData.SubscriptionStatus
                                        )}
                                    </p>
                                </PlanElem>
                                {accountData && accountData.Subscription ? (
                                    <>
                                        <PlanElem>
                                            <h4>Start date: </h4>
                                            <p>
                                                {moment(new Date(accountData.Subscription.SubscriptionStartDate))
                                                    .format("Do MMMM YYYY").toString()}
                                            </p>
                                        </PlanElem>
                                        <PlanElem>
                                            <h4>End date: </h4>
                                            <p>
                                                {moment(new Date(accountData.Subscription.SubscriptionEndDate))
                                                    .format("Do MMMM YYYY").toString()}
                                            </p>
                                        </PlanElem>
                                    </>
                                ) : null}
                                {accountData && accountData.Subscription ? (
                                    <>
                                        <PlanElem>
                                            <h4>Billing amount: </h4>
                                            <p><sup>{currencySymbol}</sup>
                                                {accountData.Subscription?.BillingAmount}</p>
                                        </PlanElem>
                                        <PlanElem>
                                            <h4>Billing frequency: </h4>
                                            <p>
                                                {PlanInterval(
                                                    accountData.Subscription
                                                        .BillingFrequency
                                                )}
                                            </p>
                                        </PlanElem>
                                    </>
                                ) : null}
                                <PlanElem>
                                    <h4>Payment method: </h4>
                                    <p>
                                        {accountData && accountData.Last4
                                            ? " " + accountData.Last4.toString()
                                            : " n/a"}
                                    </p>

                                    <PinkTextButton
                                        onClick={() => { document.dispatchEvent(new CustomEvent('updateBillingDetails')) }}
                                        disabled={false}
                                    >
                                        Update Billing Details
                                    </PinkTextButton>
                                </PlanElem>
                            </PlanTable>
                            {accountData ? (
                                <>
                                    <PrimaryText>
                                        {accountData.Subscription
                                            ? GetPlanSubText(
                                                authCtx.userData.SubscriptionStatus,
                                                new Date(
                                                    accountData.Subscription.SubscriptionEndDate
                                                ),
                                                accountData.Subscription.BillingFrequency
                                            )
                                            : null}
                                    </PrimaryText>

                                    <PinkTextButton
                                        onClick={() => { document.dispatchEvent(new CustomEvent('cancelAutoRenewal')) }}
                                        disabled={disableCancel}
                                    >
                                        Cancel Auto Renewal
                                    </PinkTextButton>
                                </>
                            ) : null}
                            <br />
                        </ContentSpit>
                    </ContentContainer>
                </AccountSection>

                <AccountSection title={"Disconnect Devices"}>
                    <ContentSpit>
                        <PrimaryText>After clicking this button, every other device will need to log in again.</PrimaryText>
                        <br />
                        <GreyButton onClick={DisconnectOtherDevices}>Disconnect Devices</GreyButton>
                    </ContentSpit>
                </AccountSection>

                <UpgradeModal
                    display={displayUpgrade}
                    setDisplay={setDisplayUpgrade}
                    setSuccess={setUpgradeSuccess}
                />

                {!disableCancel && accountData?.Subscription ? (
                    <CancelModal
                        display={displayCancel}
                        setDisplay={setDisplayCancel}
                        subscription={accountData.Subscription as ISubscriptionDTO}
                    />
                ) : null}
            </Container>

            <PopUp
                canBeDismissed={true}
                isFullScreen={true}
                openEvent="editPerson"
            >
                <Card>
                    <EditPerson />
                </Card>
            </PopUp>


            <PopUp
                canBeDismissed={true}
                isFullScreen={true}
                openEvent="editEmail"
            >
                <Card>
                    <EditEmail />
                </Card>
            </PopUp>

            <PopUp
                canBeDismissed={true}
                isFullScreen={true}
                openEvent="changePassword"
            >
                <Card>
                    <ChangePasswordForm />
                </Card>
            </PopUp>

            {!displayUpgrade ? (
                <PopUp
                    canBeDismissed={true}
                    isFullScreen={true}
                    openEvent="updateBillingDetails"
                >
                    <StripeCardContainer>
                        <StripeCard setPaymentMethod={AddStripePaymentMethod} />
                    </StripeCardContainer>
                </PopUp>
            ) : undefined}
        </>
    );
}

export default AccountScreen;

export async function Loader() {
    SetTitle("Account");
    return null;
}
