import React from "react";

const IconTime = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={props?.iconFill}
                d="M18.513 7.119A6.306 6.306 0 0020 3.083V0H4v3.083c0 1.459.528 2.892 1.487 4.035l3.086 3.68c.567.677.571 1.625.009 2.306l-3.13 3.794A6.308 6.308 0 004 20.893V24h16v-3.107c0-1.44-.517-2.858-1.453-3.994l-3.13-3.794a1.782 1.782 0 01.009-2.306l3.087-3.68zm-4.639 7.257l3.13 3.794c.652.792.996 1.726.996 2.83h-1.061C16.146 18.983 12 16 12 16s-4.147 2.983-4.94 5H6c0-1.104.343-2.039.996-2.829l3.129-3.793a3.803 3.803 0 00-.019-4.864L7.02 5.833C6.36 5.048 6 4.097 6 2.999h12c0 1.101-.363 2.05-1.02 2.834l-3.087 3.68a3.804 3.804 0 00-.019 4.863z"> </path>
        </svg>
    )
};

export default IconTime;
