import moment from "moment";
import {Suspense, useContext, useEffect, useState} from "react";
import {Await, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {ForceWatchVideo} from "../../../Api/Video";
import {GetComments} from "../../../Api/VideoComment";
import {Colours} from "../../../Constants/Colours";
import ICommentAndPersonDTO from "../../../Models/DTOs/ICommentAndPersonDTO";
import {IRatingDTO} from "../../../Models/DTOs/IRatingDTO";
import IVideoDTO from "../../../Models/DTOs/IVideoDTO";
import {RatingButtonType} from "../../../Models/Enums/RatingButtonType";
import {IPlayerPeakInfo} from "../../../Models/IPlayerPeakInfo";
import VideoComments from "../../VideoComments";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import LikeDislikeButton from "../Buttons/LikeDislikeButton";
import SocialAndRatingsLoader from "../PageLoaders/SocialAndRatingsLoader";
import WatchPageLoader from "../PageLoaders/WatchPageLoader";
import Socials from "../Socials";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";
import {AxiosError} from "axios";
import {GetRelatedContent} from "../../../Api/Carousel";
import {ContentType} from "../../../Models/Enums/ContentType";
import useWindowDimensions from "../../../Helpers/UseWindowDimensions";
import Carousel from "../../Carousels/Carousel";
import {SwiperSlide} from "swiper/react";
import CarouselItem from "../../Carousels/CarouselItem";
import ICarouselDTO from "../../../Models/DTOs/ICarouselDTO";
import VideoRedirects from "../Navigation/VideoRedirects";
import {IUserDetails} from "../../../Models/IUserDetails";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import ListContainer from "../List/ListContainer";
import VideoSingleLoader from "../PageLoaders/VideoSingleLoader";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

const Container = styled.section`
    max-width: 1632px;
    margin: 0 auto;
    width: 100%;
`;

const VideoContainerInner = styled.div`
    max-width: calc(1600rem/16);
    position: relative;
    flex-basis: 100%;
    width: 100%;
    height: 100%;
    background: #000;
    margin: 0 auto;
    max-height: calc(100vh - calc(170rem/16));
`;

const VideoContainer = styled.div`
    height: 56.25vw;
    max-height: calc(100vh - calc(170rem/16));
    @media screen and (min-width: calc(1600rem/16)) and (min-height: calc(900rem/16)) {
        height: calc(900rem/16);
    }
`;

const ContentContainer = styled.div`
    height: auto;
    padding: 1rem;
    > p {
        margin: 0 0 calc(24rem/16) 0;
        max-width: 950px;
        &:first-of-type {
            color: ${ Colours.Text };
        }
    }
`;

const UpperLayer = styled.div`
    width: 100%;
    overflow-x: auto;

    > p {
        margin: 0 0 8px 0;
        color: ${ Colours.TertiaryHighlight };
    }

    > h1 {
        width: 100%;
        margin: 0 0 calc(12rem/16) 0;
        font-size: 16pt;
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
            font-size: 18pt;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
        > h1 {
            margin: 0 0 calc(12rem/16) 0;
            width: calc(100% - (561rem/16));
        }
    }
`;

const LowerLayer = styled.div`
    overflow-x: auto;
    padding: 0 0 calc(12rem/16) 0;
    margin: calc(6rem/16) 0 calc(8rem/16) 0;
    /* Webkit */
    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    ::-webkit-scrollbar-track {
        background: #222;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #888 #222;

    > div {
        min-width: 450px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: calc(16rem/16);
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
        > div {
            min-width: 480px;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        > div {
            //min-width: 708px;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        > div {
            justify-content: space-between;
            > div {
                &:first-child {
                    margin: 0;
                    width: calc(543rem/16);
                }
                &:last-child {
                    width: calc(100% - (559rem/16));
                }
            }
        }
    }
`;

const RatingsAndSocials = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 0 0;
`;

const RatingsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 1rem 0 0;
    padding: 0 10px;
    height: 51px;
    width: 125px;
    border-radius: 25px;
    background: #050505;
    p {
        min-width: 10px;
    }
`;

const RelatedContainer = styled.div`
    margin: 0 0 2rem 0;
    h1{
        font-size: 2.5rem;
        margin: 0;
        color: ${Colours.Text};
    }
`;

async function GetVideoComments(videoId: number, controller: AbortController) {
    const result = await GetComments(videoId, controller);

    if (result === null || result === undefined || result instanceof AxiosError) {
        return [] as ICommentAndPersonDTO[];
    }

    return result;
}

function FullWatch(props: {
    videoPromise: Promise<IVideoDTO>;
    ratingPromise: Promise<IRatingDTO>;
    commentPromise: Promise<ICommentAndPersonDTO[]>;
    peakData: number;
    session: string;
}) {
    const
        authCtx = useContext(UserAuthenticationContext),
        controller = new AbortController(),
        navigate = useNavigate(),
        [ ratingActive, setRatingActive ] = useState<boolean>(false),
        [ videoLoader, setVideoLoader ] = useState<Promise<IVideoDTO>>(props.videoPromise),
        [ commentLoader, setCommentLoader ] = useState<Promise<ICommentAndPersonDTO[]>>(props.commentPromise),
        [ relatedLoader, setRelatedLoader ] = useState<Promise<ICarouselDTO | AxiosError>>(),
        [ blocked, setBlocked ] = useState<boolean>(false),
        { width } = useWindowDimensions(),
        disableComments = authCtx.userData.Person === null || authCtx.userData.Person === undefined;

    async function SetForceWatch() {
        const result = await ForceWatchVideo(
            authCtx.userData.AspNetUserId,
            props.session,
            controller
        );

        if (result instanceof AxiosError) {
            // Handle error case...
        }

        setBlocked(false);
    }

    async function UpdateComment(videoId: number) {
        const promise = GetVideoComments(videoId, controller);
        setCommentLoader(promise);
    }

    useEffect(() => {
        setCommentLoader(props.commentPromise);
    }, [props.commentPromise]);

    function SetPeakData(video: IVideoDTO, user: IUserDetails) {
        const newPeak = {
            session: props.session,
            videoId: video.Id,
            currentPeak: props.peakData,
            userId: user.AspNetUserId,
            updateBlockedState: setBlocked,
            blocked: blocked,
        } as IPlayerPeakInfo;

        return newPeak;
    }

    useEffect(() => {
        async function LoadRelated() {
            const video = await props.videoPromise;
            const user = authCtx.userData; // Use authCtx userData directly
            const userHasLifetimeAccessLevel = user.AccessLevel === 6;

            if (!video || !user.AspNetUserId) {
                return;
            }

            // If video is in Early Access && user doesn't
            // have access level of 6, redirect
            if (
                new Date(`${video.Datetime}Z`) > new Date(`${video.DatetimeEarlyAccess}Z`) &&
                new Date(`${video.Datetime}Z`) > new Date() &&
                !userHasLifetimeAccessLevel
            ) {
                // navigate(RoutePaths.Browse);
            }

            const type =
                video.SeriesId === null || video.SeriesId === undefined
                    ? ContentType.Film
                    : ContentType.Episode;

            const promise = GetRelatedContent(
                user.AspNetUserId,
                video.Id,
                type,
                user.CurrentCountryCode,
                controller
            );

            setRelatedLoader(promise);
        }

        setVideoLoader(props.videoPromise);
        LoadRelated();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.videoPromise]);

    // If user data isn't ready yet, show a loader (or handle accordingly)
    if (!authCtx.userData || !authCtx.userData.AspNetUserId) {
        return <WatchPageLoader />;
    }

    return (
        <Suspense fallback={<WatchPageLoader />}>
            <Await resolve={videoLoader}>
                {(videoData) => {
                    const video = videoData as IVideoDTO;
                    const peak = SetPeakData(video, authCtx.userData);

                    return (
                        <Suspense fallback={<WatchPageLoader />}>
                            {/* Removed Await for userSnapPromise because we're using authCtx.userData directly */}
                            <Container data-testid={"watch"}>
                                <VideoContainer>
                                    <VideoContainerInner>
                                        <VideoPlayer
                                            poster={video.Thumbnail}
                                            src={video.Link}
                                            peak={peak}
                                            startAt={peak?.currentPeak}
                                        />
                                    </VideoContainerInner>
                                </VideoContainer>

                                <ContentContainer>
                                    <UpperLayer>
                                        <PrimaryText>
                                            { moment(video.Datetime).format("Do MMMM YYYY").toString() }{ " " }
                                            { video.SeriesId && video.SeriesId > 0 ? `• Series ${video.Season} - Episode ${video.Episode}` : null }
                                        </PrimaryText>
                                        <Heading testId="videoTitle">{ video.Title }</Heading>
                                    </UpperLayer>

                                    <LowerLayer>
                                        <div>
                                            <RatingsAndSocials>
                                                <Suspense fallback={<SocialAndRatingsLoader />}>
                                                    <Await resolve={ props.ratingPromise }>
                                                        { (ratingData) => {
                                                            const loadedRating = ratingData as IRatingDTO;
                                                            return (
                                                                <RatingsContainer>
                                                                    <LikeDislikeButton
                                                                        videoId={ video.Id }
                                                                        IconSize={ 25 }
                                                                        Rating={ loadedRating }
                                                                        aspNetUserId={ authCtx.userData.AspNetUserId }
                                                                        SetRatigActive={ setRatingActive }
                                                                        Type={ RatingButtonType.Like}
                                                                        isDisabled={ false}
                                                                        testId={"likeBtn"}
                                                                    >
                                                                        <PrimaryText>
                                                                            {loadedRating.LikeCount}
                                                                        </PrimaryText>
                                                                    </LikeDislikeButton>

                                                                    <LikeDislikeButton
                                                                        videoId={video.Id}
                                                                        IconSize={25}
                                                                        Rating={loadedRating}
                                                                        aspNetUserId={ authCtx.userData.AspNetUserId }
                                                                        SetRatigActive={ setRatingActive }
                                                                        Type={RatingButtonType.Dislike}
                                                                        isDisabled={false}
                                                                        testId={"dislikeBtn"}
                                                                    >
                                                                        <PrimaryText>
                                                                            {loadedRating.DislikeCount}
                                                                        </PrimaryText>
                                                                    </LikeDislikeButton>
                                                                </RatingsContainer>
                                                            );
                                                        } }
                                                    </Await>
                                                </Suspense>

                                                <Socials
                                                    displayText={ true }
                                                    title={ video.Title }
                                                    contentType={ video.SeriesId == null || video.SeriesId === undefined ? ContentType.Film : ContentType.Episode }
                                                />
                                            </RatingsAndSocials>

                                            <VideoRedirects videoId={video.Id} />
                                        </div>
                                    </LowerLayer>

                                    <PrimaryText>{video.Description}</PrimaryText>

                                    <Suspense fallback={
                                        <ListContainer>
                                            { [...Array(5)].map((e, i) => (
                                                <VideoSingleLoader paddingTop="8" key={ i } />
                                            )) }
                                        </ListContainer>
                                    }>
                                        <Await
                                            resolve={relatedLoader}
                                        >
                                            {(relatedData) => {
                                                let relatedContent = relatedData as
                                                    | ICarouselDTO
                                                    | undefined;

                                                if (
                                                    !relatedContent ||
                                                    relatedContent instanceof AxiosError ||
                                                    !relatedContent.Content ||
                                                    relatedContent.Content.length <= 0
                                                ) {
                                                    return null;
                                                }

                                                return (
                                                    <RelatedContainer>
                                                        <Heading type={ HeadingType.H3 }>
                                                            {relatedContent.Title}
                                                        </Heading>
                                                        <Carousel
                                                            arrows={true}
                                                            autoPlay={false}
                                                            bullets={false}
                                                            sliderPerView={
                                                                width > 1115
                                                                    ? 4
                                                                    : 2
                                                            }
                                                            spaceBetween={10}
                                                            loop={false}
                                                        >
                                                            {relatedContent.Content.map(
                                                                (content, index) => (
                                                                    <SwiperSlide
                                                                        key={
                                                                            index.toString() +
                                                                            content.Id
                                                                        }
                                                                    >
                                                                        <CarouselItem
                                                                            Content={ content }
                                                                        />
                                                                    </SwiperSlide>
                                                                )
                                                            )}
                                                        </Carousel>
                                                    </RelatedContainer>
                                                );
                                            }}
                                        </Await>
                                    </Suspense>

                                    <Suspense fallback={
                                        <Heading>...Loading comments</Heading>
                                    }>
                                        <Await resolve={commentLoader}>
                                            {(commentData) => {
                                                let comments = commentData as ICommentAndPersonDTO[];

                                                return (
                                                    <VideoComments
                                                        videoId={video.Id}
                                                        comments={comments}
                                                        UpdateComments={UpdateComment}
                                                        disable={disableComments}
                                                        disableText={"To write a comment, go to the account settings and add a first name to your account."}
                                                    />
                                                );
                                            }}
                                        </Await>
                                    </Suspense>
                                </ContentContainer>

                                {/*<ForceWatchModal
                                    display={blocked}
                                    setDisplay={() => setBlocked(false)}
                                    setForceWatch={SetForceWatch}
                                    testId={"forceWatch"}
                                />*/}
                            </Container>
                        </Suspense>
                    );
                }}
            </Await>
        </Suspense>
    );
}

export default FullWatch;
