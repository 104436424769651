import { useContext, useEffect, useReducer, useRef, useState } from "react";
import styled from "styled-components";
import { CancelSubscription } from "../../../Api/Stripe";
import { ICancelDTO } from "../../../Models/DTOs/ICancelDTO";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import ISelecter from "../../../Models/DTOs/ISelecter";
import { CancellationReason } from "../../../Models/Enums/CancellationReason";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import { toast } from 'react-toastify';
import PinkButton from "../Buttons/PinkButton";
import TextArea from "../Inputs/TextArea";
import Selecter from "../Selecters/Selecter";
import SuccessText from "../Text/SuccessText";
import { AxiosError } from "axios";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
`;

function Cancel() {
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [reasonChanged, setReasonChanged] = useState<boolean>(false);
    const [changeChanged, setChangeChanged] = useState<boolean>(false);
    const [changeError, setChangeError] = useState<string>();
    const [reasonError, setReasonError] = useState<string>();
    const reasonRef = useRef<HTMLInputElement | null>(null);
    const changeRef = useRef<HTMLTextAreaElement | null>(null);

    const options: ISelecter[] = [
        { value: CancellationReason.Value_For_Money, label: "Value of Money" },
        {
            value: CancellationReason.Technical_Issues,
            label: "Technical Issues",
        },
        {
            value: CancellationReason.Type_of_Content,
            label: "Type of Content",
        },
        { value: CancellationReason.Other, label: "Other" },
    ];

    function GetChangeValidState(text: string) {
        setChangeChanged(true);

        if (text.length <= 0 || text.trim().length <= 0) {
            setChangeError("Suggestion must contain a value.");
            return InputIsValid.Invalid;
        }

        setChangeError(undefined);
        return InputIsValid.Valid;
    }

    function GetReasonValidState(text: string) {
        setReasonChanged(true);
        if (text.length <= 0 || text.trim().length <= 0) {
            setReasonError("A reason must be selected.");
            return InputIsValid.Invalid;
        }

        setReasonError(undefined);
        return InputIsValid.Valid;
    }

    function changeReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetChangeValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetChangeValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: "",
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function reasonReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetReasonValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetReasonValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: "",
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    const [changeState, dispatchChange] = useReducer(changeReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [reasonState, dispatchReason] = useReducer(reasonReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const changeIsValid =
        changeState.IsValid !== InputIsValid.Invalid && changeState.IsValid !== InputIsValid.NotSet && changeError === undefined;

    const reasonIsValid =
        reasonState.IsValid !== InputIsValid.Invalid && reasonState.IsValid !== InputIsValid.NotSet && reasonError === undefined;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(changeIsValid && reasonIsValid);
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [changeIsValid, reasonIsValid]);

    function ChangeHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        dispatchChange({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    function ReasonOnChange(option: ISelecter) {
        dispatchReason({
            Type: InputState.User_Input,
            Value: option.value,
        } as IInputDTO);
    }

    function validateChangeHandler() {
        dispatchChange({
            Value: changeState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    };

    function validateReasonHandler() {
        dispatchReason({
            Value: reasonState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (authCtx?.userData) {
            setIsLoading(true);

            dispatchChange({
                Value: changeState.Value,
                Type: InputState.Input_Blur,
            } as IInputDTO);

            dispatchReason({
                Value: reasonState.Value,
                Type: InputState.Input_Blur,
            } as IInputDTO);

            if(formIsValid && changeState.Type !== InputState.Not_Set && reasonState.Type !== InputState.Not_Set){
                const data: ICancelDTO = {
                    AspNetUserId: authCtx?.userData.AspNetUserId,
                    Message: changeState.Value,
                    Reason: reasonState.Value as CancellationReason
                }
                const result = await CancelSubscription(data, authCtx?.userData.Access_Token, controller);

                let success;

                if(result instanceof AxiosError){
                    success = false;
                }
                else{
                    success = result;
                }

                setSuccess(success);

                if (!success) {
                    setChangeError("Subscription failed to cancel. Please contact support");
                    setIsLoading(false);
                    return;
                }

                toast.success("Your subscription has been successfully cancelled.");
                await authCtx.refreshUserData(authCtx?.userData.Access_Token);

            }else if (!changeIsValid) {
                changeRef?.current?.focus();
            }else if (!reasonIsValid) {
                reasonRef?.current?.focus();
            }

            setIsLoading(false);
        }
    }

    return (
        <Container onSubmit={OnSubmit}>
            <Selecter
                ref={reasonRef}
                options={options}
                placeholder={"Reason for cancelling"}
                onChange={ReasonOnChange}
                onBlur={validateReasonHandler}
                isValid={reasonIsValid || !reasonChanged}
                errorMessage={reasonError}
            />
            <TextArea
                ref={changeRef}
                placeholder="What would you like to see from Ickonic to change your mind?"
                onChange={ChangeHandler}
                onBlur={validateChangeHandler}
                isValid={changeIsValid || !changeChanged}
                errorMessage={changeError}
            />
            {success ? (
                <SuccessText>Subscription successfully cancelled!</SuccessText>
            ) : null}
            <PinkButton type={"submit"} disabled={isLoading || !formIsValid}>
                Cancel Auto Renewal
            </PinkButton>
        </Container>
    );
}

export default Cancel;
