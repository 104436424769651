import { createContext, useState, useEffect, ReactNode } from "react";
import lscache from "lscache";

// Models
import { IUserDetails } from "../Models/Interfaces/IUserDetails";

// Api
import { GetUserDetails } from "../Api/Account";

// Define the shape of your context's value
interface UserAuthenticationContextTypes {
    userData: IUserDetails;
    setUserData: (userData: IUserDetails) => void;
    doesAuthTokenExist: () => boolean;
    storeToken: (token: string) => void;
    refreshUserData: (token: string) => void;
    fetchUserData: (token: string) => Promise<IUserDetails | null>;
    logOutUser: () => void;
}

export const UserAuthenticationContext = createContext<UserAuthenticationContextTypes>({} as UserAuthenticationContextTypes);

export const UserAuthenticationContextProvider = (props: { children: ReactNode }) => {

    // Attempt to rehydrate userData from lscache if you want it persistent
    const
        cachedUserData = lscache.get("userData") as IUserDetails | null,
        [userData, setUserDataState] = useState<IUserDetails>(
            cachedUserData ? cachedUserData : ({} as IUserDetails)
        ),

        doesAuthTokenExist = () => {
            const token = lscache.get("authToken");
            return !!token;
        },

        storeToken = (token: string) => {

            // Store the token in localStorage with lscache
            // If you need an expiration (in minutes), provide
            // a third param, e.g. lscache.set("authToken", token, 60);
            lscache.set("authToken", token);
        },

        fetchUserData = async (token: string): Promise<IUserDetails | null> => {
            const abortController = new AbortController();

            if (token) {
                try {
                    const response = await GetUserDetails(token, abortController);
                    const userDetailsData = response as IUserDetails;

                    if (userDetailsData && userDetailsData.AspNetUserId) {
                        setUserData(userDetailsData);
                        return userDetailsData;
                    }
                } catch (error) {
                    console.error("Failed to fetch user details", error);
                    throw error;
                }
            }

            return null;
        },

        refreshUserData = async (token: string) => {
            const userDetailsData = await fetchUserData(token);

            // If userDetailsData matches IUserDetails and has a valid AspNetUserId, update state
            if (userDetailsData && userDetailsData.AspNetUserId) {
                setUserData(userDetailsData);
            } else {
                // Optionally handle scenario where no valid user details returned
                // e.g., setUserData({} as IUserDetails);
            }
        },

        setUserData = (newUserData: IUserDetails) => {
            setUserDataState(newUserData);
        },

        logOutUser = () => {

            // Remove auth token from lscache
            lscache.remove("authToken");

            // Clear user data state
            setUserData({} as IUserDetails);
        };

    const value: UserAuthenticationContextTypes = {
        userData,
        setUserData,
        doesAuthTokenExist,
        storeToken,
        refreshUserData,
        fetchUserData,
        logOutUser
    };

    return <UserAuthenticationContext.Provider value={value}>{props.children}</UserAuthenticationContext.Provider>;
};
