import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { NavLink } from "react-router-dom";

// Models
import { ICarouselPresenterItem } from "../../Models/Interfaces/ICarouselPresenterItem";

// Assets
import ButtonsPrevNext from "../Buttons/ButtonsPrevNext";

type CarouselLandscapeProps = {
    isDarkMode: boolean;
    heading: string;
    accentColor?: string;
    carouselItems?: ICarouselPresenterItem[];
};

const CarouselLandscape: React.FC<CarouselLandscapeProps> = ({ isDarkMode, heading, accentColor, carouselItems = dummyItems }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    // Correctly initialize state hooks separately
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerView, setItemsPerView] = useState(2);
    const [containerWidth, setContainerWidth] = useState(0);

    const updateItemsPerView = useCallback(() => {
        const width = window.innerWidth;
        if (width < 760) {
            return 2;
        } else if (width < 1008) {
            return 3;
        } else if (width < 1272) {
            return 4;
        } else {
            return 5;
        }
    }, []);

    // Calculate maxIndex based on the number of items and items per view
    const maxIndex = Math.max(0, carouselItems.length - itemsPerView);

    const goToPrev = () => {
        setCurrentIndex((prev) => Math.max(prev - itemsPerView, 0));
    };

    const goToNext = () => {
        setCurrentIndex((prev) => Math.min(prev + itemsPerView, maxIndex));
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => goToNext(),
        onSwipedRight: () => goToPrev(),
        trackMouse: true,
    });

    // On mount and on resize, update container width and items per view
    useEffect(() => {
        const handleResize = () => {
            const newItemsPerView = updateItemsPerView();
            setItemsPerView(newItemsPerView);
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };

        // Initial measurement
        handleResize();

        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [updateItemsPerView]);

    // Adjust currentIndex if itemsPerView or carouselItems.length changes
    useEffect(() => {
        const newMaxIndex = Math.max(0, carouselItems.length - itemsPerView);
        setCurrentIndex((prevIndex) => Math.min(prevIndex, newMaxIndex));
    }, [itemsPerView, carouselItems.length]);

    // Calculate pixel-based layout
    const itemWidth = containerWidth && itemsPerView ? containerWidth / itemsPerView : 0;
    const trackWidth = itemWidth * carouselItems.length;
    const translateX = -currentIndex * itemWidth;

    return (
        <div
            className={`layout--carousel-landscape ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'}`}
            {...handlers}
        >
            <h2 className={accentColor ? 'has-accent-color' : 'no-accent-color'}>
                {accentColor && (<span style={{background: accentColor}}/>)} {heading}
            </h2>

            <div ref={containerRef} className="carousel-landscape__inner">
                <div
                    className="carousel-landscape__track"
                    style={{
                        width: trackWidth ? `${trackWidth}px` : 'auto',
                        transform: `translateX(${translateX}px)`,
                    }}
                >
                    {carouselItems.map((item, i) => (
                        <div
                            key={i}
                            className="carousel-landscape__item u-relative_hidden"
                            style={{ width: `${itemWidth}px` }}
                        >
                            <NavLink to={item.link}>
                                <div
                                    className="carousel-landscape__thumbnail"
                                    style={{
                                        backgroundImage: `url(${item.bgImage})`,
                                    }}
                                />
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>

            <ButtonsPrevNext
                onPrev={goToPrev}
                onNext={goToNext}
                currentIndex={currentIndex}
                maxIndex={maxIndex}
                parentClass="carousel-landscape__controls"
            />
        </div>
    );
};

export default CarouselLandscape;

const dummyItems: ICarouselPresenterItem[] = [
    { label: "Landscape Item 1", bgImage: "", link: "" },
    { label: "Landscape Item 2", bgImage: "", link: "" },
    { label: "Landscape Item 3", bgImage: "", link: "" },
    { label: "Landscape Item 4", bgImage: "", link: "" },
    { label: "Landscape Item 5", bgImage: "", link: "" },
    { label: "Landscape Item 6", bgImage: "", link: "" },
    { label: "Landscape Item 7", bgImage: "", link: "" },
    { label: "Landscape Item 8", bgImage: "", link: "" },
    { label: "Landscape Item 9", bgImage: "", link: "" },
];
