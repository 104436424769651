import React from "react";

const IconNews = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={ props?.iconFill }
                d="M22 23H2a2 2 0 01-2-2V3h1v18.094c0 .276.183.601.508.607.278.005.492-.23.492-.557V1h22v20c0 1.242-1.042 2-2 2zm1-21H3v20h19c.48 0 1-.379 1-1V2zm-2 17v1H5v-1h16zm0-3v1H5v-1h16zm0-3v1h-6v-1h6zm-8 1H5v-4h8v4zm-1-3H6v2h6v-2zm9-1v1h-6v-1h6zm-5.254-2c.722 0 1.236-.401 1.236-1.077 0-.741-.566-.927-1.07-1.087-.518-.165-.599-.275-.599-.436 0-.14.119-.265.357-.265.304 0 .461.155.575.406l.642-.396c-.243-.516-.671-.791-1.217-.791-.576 0-1.118.39-1.118 1.066 0 .672.485.922.98 1.067.499.146.689.231.689.446 0 .135-.09.286-.451.286-.376 0-.581-.196-.699-.486l-.657.4c.186.516.633.867 1.332.867zm2.686 0c.723 0 1.236-.401 1.236-1.077 0-.741-.566-.927-1.07-1.087-.518-.165-.599-.275-.599-.436 0-.14.119-.265.357-.265.304 0 .461.155.575.406l.642-.396c-.242-.516-.67-.791-1.217-.791-.575 0-1.117.39-1.117 1.066 0 .672.485.922.979 1.067.499.146.69.231.69.446 0 .135-.091.286-.452.286-.376 0-.58-.196-.699-.486l-.656.4c.185.516.632.867 1.331.867zm-11.34-.07h-.76V4.424h1.26c.656 0 1.165.536 1.165 1.202 0 .666-.509 1.202-1.165 1.202h-.5V7.93zm7.037-2.735h-1.307v.581h1.188v.761h-1.188v.622h1.331v.771h-2.092V4.424h2.068v.771zM9.898 7.93h-.761V4.424h1.331c.633 0 1.141.536 1.141 1.202 0 .431-.247.821-.613 1.017l.708 1.287h-.817l-.628-1.152h-.361V7.93zM7.592 5.175h-.5v.902h.5c.233 0 .404-.196.404-.451 0-.256-.171-.451-.404-.451zm2.876 0h-.57v.902h.57c.209 0 .381-.196.381-.451 0-.256-.172-.451-.381-.451z"></path>
        </svg>
    )
};

export default IconNews;
