import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { GetSeries } from "../../../../Api/Carousel";
import CarouselItem from "../../../../Components/Carousels/CarouselItem";
import ListContainer from "../../../../Components/UI/List/ListContainer";
import { SetTitle } from "../../../../Helpers/Utility";
import ICarouselContentDTO from "../../../../Models/DTOs/ICarouselContentDTO";
import { PopUpType } from "../../../../Models/Enums/PopUpType";
import PopUp from "../../../../Components/UI/Modals/PopUp";
import Banner from "../../../../Components/UI/Banner";
import { BannerType } from "../../../../Models/Enums/BannerType";
import VideoSingleLoader from "../../../../Components/UI/PageLoaders/VideoSingleLoader";
import { ResponsiveBreakpoints } from "../../../../Constants/ResponsiveBreakpoints";

// Context
import { UserAuthenticationContext } from "../../../../Context/UserAuthenticationContext";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        padding: 1.5rem;
    }
`;

const take = 30;

function SeriesScreenFree() {
    const authCtx = useContext(UserAuthenticationContext);
    const [initialSeries, setInitialSeries] = useState<ICarouselContentDTO[] | null>(null);
    const [series, setSeries] = useState<ICarouselContentDTO[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

    useEffect(() => {
        // Wait until user data is available
        if (!authCtx.userData) {
            return;
        }

        async function fetchInitialSeries() {
            setLoadingInitial(true);
            const result = await GetSeries(
                authCtx.userData.CurrentCountryCode,
                authCtx.userData.AspNetUserId,
                take,
                0
            );

            if (!result || result instanceof AxiosError) {
                setInitialSeries([]);
            } else {
                setInitialSeries(result);
            }

            // Set the page title
            SetTitle("Series");
            setLoadingInitial(false);
        }

        fetchInitialSeries();
    }, [authCtx.userData]);

    async function loadMoreSeries(skip: number) {
        if (!authCtx.userData) {
            return;
        }

        const result = await GetSeries(
            authCtx.userData.CurrentCountryCode,
            authCtx.userData.AspNetUserId,
            take,
            skip
        );

        if (!result || result instanceof AxiosError || result.length <= 0) {
            setHasMore(false);
            return;
        }

        setSeries((prev) => [...prev, ...result]);
    }

    if (loadingInitial) {
        return (
            <>
                <PopUp
                    canBeDismissed={true}
                    apiEnum={PopUpType.Series}
                    isFullScreen={true}
                />

                <Banner apiEnum={BannerType.Series} />

                <Container>
                    <ListContainer>
                        {[...Array(take)].map((_, i) => (
                            <VideoSingleLoader paddingTop="0" key={i} />
                        ))}
                    </ListContainer>
                </Container>
            </>
        );
    }

    const mergedLists = initialSeries ? [...initialSeries, ...series] : [];

    return (
        <>
            <PopUp
                canBeDismissed={true}
                apiEnum={PopUpType.Series}
                isFullScreen={true}
            />

            <Banner apiEnum={BannerType.Series} />

            <Container>
                <InfiniteScroll
                    dataLength={mergedLists.length}
                    hasMore={hasMore}
                    next={() => loadMoreSeries(mergedLists.length)}
                    loader={
                        <ListContainer>
                            {[...Array(5)].map((_, i) => (
                                <VideoSingleLoader paddingTop="calc(16rem/16)" key={i} />
                            ))}
                        </ListContainer>
                    }
                >
                    <ListContainer>
                        {mergedLists.map((content, index) => (
                            <CarouselItem
                                key={index.toString() + content.Id}
                                Content={content}
                                disableOverlay={true}
                            />
                        ))}
                    </ListContainer>
                </InfiniteScroll>
            </Container>
        </>
    );
}

export default SeriesScreenFree;
