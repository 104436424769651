import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SetTitle } from "../../Helpers/Utility";
import { GetVideo, GetVideoPeak } from "../../Api/Video";
import { IRatingDTO } from "../../Models/DTOs/IRatingDTO";
import { GetVideoRating } from "../../Api/Rating";
import { GetComments } from "../../Api/VideoComment";
import { RoutePaths } from "../../Constants/RoutePaths";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import ICommentAndPersonDTO from "../../Models/DTOs/ICommentAndPersonDTO";
import { IsActive } from "../../Helpers/UserUtility";
import FullWatch from "../../Components/UI/Watch/FullWatch";
import FreeWatch from "../../Components/UI/Watch/FreeWatch";
import { AxiosError } from "axios";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

// Helper function to fetch comments safely
async function GetVideoCommentsSafe(videoId: number): Promise<ICommentAndPersonDTO[]> {
    const controller = new AbortController();
    try {
        const result = await GetComments(videoId, controller);
        if (result instanceof AxiosError) {
            return [];
        }
        return result ?? [];
    } catch (error) {
        return [];
    }
}

function WatchScreen() {
    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();
    const { id } = useParams<string>();

    const [videoData, setVideoData] = useState<IVideoDTO | null>(null);
    const [ratingData, setRatingData] = useState<IRatingDTO | undefined>(undefined);
    const [commentsData, setCommentsData] = useState<ICommentAndPersonDTO[]>([]);
    const [peakData, setPeakData] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [useFree, setUseFree] = useState<boolean>(false);
    const session = uuidv4();

    const isAuth = IsActive(authCtx.userData);

    useEffect(() => {
        // Check user authentication and video ID validity
        if (!id || isNaN(Number(id)) || Number(id) === 0) {
            navigate(RoutePaths.Browse);
            return;
        }

        const videoId = Number(id);

        if (!authCtx.userData) {
            // If user data not loaded yet, wait for it
            return;
        }

        const controller = new AbortController();

        async function fetchData() {
            setLoading(true);

            // Fetch video
            let video: IVideoDTO | null = null;
            try {
                const result = await GetVideo(videoId, authCtx.userData.CurrentCountryCode, controller);
                if (result && "Id" in result && result.Id) {
                    video = result;
                    SetTitle(video.Title ?? "Video");
                } else {
                    navigate(RoutePaths.Browse);
                    return;
                }
            } catch (error) {
                navigate(RoutePaths.Browse);
                return;
            }

            // Fetch rating
            let rating: IRatingDTO | undefined = undefined;
            if (authCtx.userData && authCtx.userData.AspNetUserId) {
                const ratingResult = await GetVideoRating(videoId, authCtx.userData.AspNetUserId, controller);
                if (ratingResult && !(ratingResult instanceof AxiosError)) {
                    rating = ratingResult;
                }
            }

            // Fetch comments
            const comments = await GetVideoCommentsSafe(videoId);

            // Fetch peak
            let peak = 0;
            if (isAuth && authCtx.userData.AspNetUserId) {
                try {
                    const peakResult = await GetVideoPeak(authCtx.userData.AspNetUserId, videoId, controller);
                    if (!(peakResult instanceof AxiosError)) {
                        peak = peakResult?.Peak ?? 0;
                    }
                } catch {
                    peak = 0;
                }
            }

            setVideoData(video);
            setRatingData(rating);
            setCommentsData(comments);
            setPeakData(peak);
            setUseFree(!isAuth);
            setLoading(false);
        }

        fetchData();

        return () => {
            controller.abort();
        };
    }, [id, authCtx.userData, isAuth, navigate]);

    if (loading || !videoData) {
        return <div>Loading video...</div>;
    }

    return useFree ? (
        <FreeWatch
            videoPromise={Promise.resolve(videoData)}
            ratingPromise={Promise.resolve(ratingData ?? ({} as IRatingDTO))}
            commentPromise={Promise.resolve(commentsData)}
        />
    ) : (
        <FullWatch
            videoPromise={Promise.resolve(videoData)}
            ratingPromise={Promise.resolve(ratingData ?? ({} as IRatingDTO))}
            commentPromise={Promise.resolve(commentsData)}
            peakData={peakData}
            session={session}
        />
    );
}

export default WatchScreen;
