import React from "react";

const IconShopTag = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M12.628 21.412l5.969-5.97 1.458 3.71L7.715 24 2.907 11.762l9.721 9.65zM11.352 0H2v9.453L12.625 20 22 10.625 11.352 0zm4.025 9.476c-.415-.415-.865-.617-1.378-.617-.578 0-1.227.241-2.171.804-.682.41-1.118.584-1.456.584-.361 0-1.083-.408-.961-1.218.052-.345.25-.697.572-1.02.652-.651 1.544-.848 2.276-.106l.744-.744c-.476-.476-1.096-.792-1.761-.792-.566 0-1.125.227-1.663.677l-.626-.627-.698.699.653.652c-.569.826-.842 2.021.076 2.938 1.011 1.011 2.188.541 3.413-.232.6-.379 1.083-.563 1.475-.563.589 0 1.18.498 1.078 1.258-.052.386-.26.763-.621 1.122-.451.451-.904.679-1.347.679-.418 0-.747-.192-1.049-.462l-.739.739c.463.458 1.082.753 1.735.753.544 0 1.087-.201 1.612-.597l.54.538.697-.697-.52-.521c.743-.896 1.157-2.209.119-3.247zM5.699 2a1.7 1.7 0 110 3.399 1.7 1.7 0 010-3.399z"></path>
        </svg>
    )
};

export default IconShopTag;
