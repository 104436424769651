import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import styled from "styled-components";

// Assets
import IconLoaderCircle from "../../Assets/SVGs/Icons/LoaderCircle";
import IconAlertError from "../../Assets/SVGs/Icons/AlertError";
import IconCheckMark from "../../Assets/SVGs/Icons/CheckMark";

// Contsants
import {RoutePaths} from "../../Constants/RoutePaths";
import {Colours} from "../../Constants/Colours";

interface PopUpProps {
    isOpen: boolean;
    onClose: () => void;
    padding?: string | number;
    borderRadius?: string | number;
    isDarkMode?: boolean;
    progressMessage?: string;
    progressSteps: ProgressStep[];
}

interface ProgressStep {
    percent: number;
    status: 'pending' | 'success' | 'error';
}

const PopUpProgressPanelDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: background .45s cubic-bezier(.84,0,.13,.98), transform .45s cubic-bezier(.84,0,.13,.98);
    &.is-transitioning-in {
        background-color: rgba(0, 0, 0, 0.7);
        transition: background .45s cubic-bezier(.84,0,.13,.98) .01s, transform .45s cubic-bezier(.84,0,.13,.98) .01s;
        .popup__inner {
            opacity: 1;
            transform: scale(1);
            transition: opacity .45s cubic-bezier(.84,0,.13,.98) .01s, transform .45s cubic-bezier(.84,0,.13,.98) .01s;
        }
    }

    .popup__inner {
        padding: 1rem;
        width: 100%;
        max-width: calc(400rem/16);
        max-height: 80%;
        position: relative;
        opacity: 0;
        transform: scale(.95);
        overflow-y: auto;
        transition: opacity .25s cubic-bezier(.84,0,.13,.98), transform .25s cubic-bezier(.84,0,.13,.98);
        svg {
            width: calc(40rem/16);
            height: calc(40rem/16);
            margin: 0 auto 1rem auto;
            display: block;
            &.popup__loader {
                animation: spin 0.5s infinite linear;
            }

            * {
                fill: white;
            }
        }

        p {
            text-align: center;
            font-size: calc(14rem/16);
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: calc(20rem/16);
                    height: calc(20rem/16);
                    margin: 0 calc(10rem/16) 0 0;
                }

                &.progress-panel__login-switch {
                    a {
                        margin-left: calc(6rem/16);
                        text-decoration: none;
                        color: ${Colours.IckonicPinkHighlight};
                        &:hover,
                        &:focus,
                        &:active {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        h1 {
            font-family: "Outfit", Helvetica sans-serif;
            text-align: center !important;
            font-size: calc(24rem/16) !important;
        }

        @media screen and (min-width: 36.5em) and (min-height: calc(1000em/16)) {
            margin-bottom: 20vh;
        }
    }

    .progress__bar-wrapper {
        position: relative;
        height: calc(8rem/16);
        border-radius: calc(4rem/16);
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        background: rgba(255,255,255,0.1);
        display: flex;
        .progress__segment {
            height: 100%;
            transition: width 0.25s cubic-bezier(.84,0,.13,.98);
            &--pending {
                background-color: #e8bb27; // Yellow for pending
            }

            &--success {
                background: #b760b4; // Green for success
            }

            &--error {
                background-color: #da2626; // Red for error
            }
        }
    }
`;

const ProgressPanel: React.FC<PopUpProps> = ({
    isOpen,
    onClose,
    padding,
    borderRadius,
    progressMessage,
    isDarkMode,
    progressSteps
}) => {
    let closeTimeout: NodeJS.Timeout;
    const
        popupRef = useRef<HTMLDivElement>(null),
        [transitionDirection, setTransitionDirection] = useState<string>("out"),
        [hasError, setHasError] = useState<boolean>(false),
        [fullSuccess, setFullSuccess] = useState<boolean>(false),
        [redirectTimeout, setRedirectTimeout] = useState<NodeJS.Timeout | null>(null),
        [accountAlreadyExists, setAccountAlreadyExists] = useState<boolean>(false),

        navigate = useNavigate(),

        handleClose = () => {
            closeTimeout = setTimeout(() => {
                onClose();
            }, 450);
        },

        handleErrorRefresh = () => {
            window.location.reload();
        },

        renderProgressSegments = () => {
            let completedPercent = 0;
            let errorOccurred = false;

            return progressSteps.map((step, index) => {
                if (errorOccurred) {
                    return null; // Don't render any steps after an error
                }

                let width = 0;
                if (step.status === 'success' || step.status === 'error') {
                    width = step.percent - completedPercent;
                    completedPercent = step.percent;
                }

                if (step.status === 'error') {
                    errorOccurred = true;
                }

                return (
                    <div
                        key={index}
                        className={`progress__segment progress__segment--${step.status}`}
                        style={{
                            width: `${width}%`,
                            opacity: step.status !== 'pending' ? 1 : 0.3
                        }}
                    />
                );
            }).filter(Boolean); // Remove null elements
        };

    useEffect(() => {
        let closeTimeout: NodeJS.Timeout;

        const handleOutsideClick = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setTransitionDirection("out");
                closeTimeout = setTimeout(() => {
                    onClose();
                }, 450);
            }
        };

        if (isOpen) {
            setTransitionDirection("in");
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            if (closeTimeout) {
                clearTimeout(closeTimeout);
            }
            if (redirectTimeout) {
                clearTimeout(redirectTimeout);
            }
        };
    }, [isOpen, onClose, redirectTimeout]);

    useEffect(() => {
        for (const step of progressSteps) {
            if (step.status === 'error') {
                setHasError(true);
                if (step.percent === 25) {
                    setAccountAlreadyExists(true);
                }
                break;
            }

            if (step.status === 'success' && step.percent === 100) {
                setFullSuccess(true);
                const timeout = setTimeout(() => {

                    // redirect with refresh
                    const fullUrl = `${window.location.origin}${RoutePaths.SignUpSuccess}`;
                    window.location.href = fullUrl;
                }, 3500);
                setRedirectTimeout(timeout);
            }
        }

        return () => {
            if (redirectTimeout) {
                clearTimeout(redirectTimeout);
            }
        };
    }, [progressSteps, navigate]);

    if (!isOpen) return null;

    return (
        <PopUpProgressPanelDiv
            className={`popup popup--progress-panel ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'} ${transitionDirection === "in" ? 'is-transitioning-in' : 'is-transitioning-out'}`}>
            <div className="popup__inner">
                {hasError ? IconAlertError() : (
                    fullSuccess ? IconCheckMark() : (<IconLoaderCircle className="popup__loader"/>)
                )}

                <h1>
                    {hasError ? "Error Occurred" : (
                        fullSuccess ? "Success!" : progressMessage
                    )}
                </h1>

                <div className="progress__bar-wrapper">
                    {renderProgressSegments()}
                </div>

                <p>
                    {hasError ? (
                        accountAlreadyExists ?
                            (
                                <>
                                    <span
                                        className="progress-panel__login-switch">Is this email already in use? <NavLink
                                        to={RoutePaths.Login}>Login</NavLink></span>
                                    <span onClick={() => handleErrorRefresh()} style={{cursor: 'pointer', marginTop: '10px'}}>or close and refresh</span>
                                </>
                            )
                            :
                            (
                                <span onClick={() => handleErrorRefresh()} style={{cursor: 'pointer'}}>Close and refresh</span>
                            )
                    ) : (
                        fullSuccess && (<span><IconLoaderCircle className="popup__loader"/> Redirecting...</span>)
                    )}
                </p>
            </div>
        </PopUpProgressPanelDiv>
    );
};

export default ProgressPanel;
