import React from "react";

const IconTV = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path fill={props?.iconFill}
                  d="M22 4v12H2V4h20zm2-2H0v16h24V2zM7 20c1 1.194 2.862 2 5 2s4-.806 5-2H7z"></path>
        </svg>
    )
};

export default IconTV;
