import { AxiosError } from "axios";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AddOrEditPerson } from "../../../Api/Person";
import { IsNameValid } from "../../../Helpers/Utility";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import { IPerson } from "../../../Models/IPerson";
import PinkButton from "../Buttons/PinkButton";
import Input from "../Inputs/Input";
import PrimaryText from "../Text/PrimaryText";
import SuccessText from "../Text/SuccessText";
import styled from "styled-components";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

const Form = styled.form`
    width: 100%;
    display: block;
    max-width: calc(600rem/16);
    margin: 0 auto;
    > div {
        margin: 0 0 calc(8rem/16) 0;
    }

    > p {
        line-height: 1;
        margin: 0 0 calc(8rem/16) 0;
    }

    > button {
        width: 100%;
        border-radius: calc(7rem/16);
        font-size: 12pt;
        padding: calc(8rem/16) calc(10rem/16);
    }
`;

function EditPerson() {
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();
    const [formIsValid, setFormIsValid] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const firstNameRef = useRef<HTMLInputElement | null>(null);

    const [firstNameState, dispatchFirstName] = useReducer(firstNameReducer, {
        Value: authCtx?.userData.Person?.FirstName,
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const firstNameIsValid =
        firstNameState.IsValid === InputIsValid.Valid ||
        firstNameState.IsValid === InputIsValid.NotSet;

    function GetValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setErrorMessage("First name must have a value.");
            return InputIsValid.Invalid;
        }

        if (!IsNameValid(text)) {
            setErrorMessage("First name is not valid.");
            return InputIsValid.Invalid;
        }

        setErrorMessage(undefined);
        return InputIsValid.Valid;
    }

    function firstNameReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(firstNameIsValid);
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [firstNameIsValid]);

    function firstNameChangeHandler(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        dispatchFirstName({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    const validateFirstNameHandler = () => {

        if (firstNameState.Value === null || firstNameState.Value === undefined) {
            firstNameState.Value = "";
        }

        dispatchFirstName({
            Value: firstNameState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    };

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (authCtx?.userData) {
            setIsLoading(true);

            let person =
                authCtx?.userData.Person !== undefined
                    ? authCtx?.userData.Person
                    : ({} as IPerson);

            dispatchFirstName({
                Type: InputState.Input_Blur,
                Value: firstNameState.Value,
            } as IInputDTO);

            if (person === null || person === undefined) {
                person = {
                    AspNetId: authCtx?.userData.AspNetUserId,
                    FirstName: "",
                    LastName: "",
                    Id: 0,
                    IsOnboarded: false,
                    PrefContentType: "",
                    HowDidYouHear: "",
                    PrefCategories: "",
                }
            }

            if (formIsValid) {
                person.FirstName = firstNameState.Value;

                const result = await AddOrEditPerson(
                    person,
                    authCtx?.userData.Access_Token,
                    controller
                );

                let success;

                if(result instanceof AxiosError){
                    success = false;
                }
                else{
                    success = result;
                }

                setSuccess(success);

                if (!success) {
                    setErrorMessage("First name failed to update");
                    setIsLoading(false);
                    return;
                }

                setErrorMessage(undefined);
                authCtx.refreshUserData(authCtx.userData.Access_Token);
            } else if (!firstNameIsValid) {
                firstNameRef?.current?.focus();
            }

            setIsLoading(false);
        }
    }

    return (
        <Form onSubmit={OnSubmit}>
            <PrimaryText>First Name:</PrimaryText>

            <Input
                ref={firstNameRef}
                isValid={firstNameIsValid}
                onBlur={validateFirstNameHandler}
                onChange={firstNameChangeHandler}
                defaultValue={firstNameState.Value}
                type="text"
                placeholder="Enter first name here..."
                errorMessage={errorMessage}
            />

            <PinkButton
                disabled={
                    !firstNameIsValid ||
                    isLoading ||
                    (authCtx?.userData.Person !== undefined && authCtx?.userData.Person !== null &&
                        firstNameState.Value ===
                            authCtx?.userData.Person.FirstName)
                }
            >
                Submit Details
            </PinkButton>

            {success ? (
                <SuccessText>Success! Name changed successfully!</SuccessText>
            ) : false}
        </Form>
    );
}

export default EditPerson;
