import { useContext } from "react";
import Modal from "./Modal";
import { DeviceLimit, GetPlanPeriod, GetPlanTitle, GetPlanType } from "../../../Helpers/Utility";
import Upgrade from "../Forms/Upgrade";
import styled from "styled-components";
import { PlanType } from "../../../Models/Enums/PlanType";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

const UpgradeContainer = styled.div`
  margin-top:15px;
  > div {
    margin: 2rem 0 0 0;
  }
`;

function UpgradeModal(props: {display: boolean, setDisplay: (value: boolean) => void, setSuccess?: (value: boolean) => void}) {
    const authCtx = useContext(UserAuthenticationContext);

    const currentPlan = GetPlanType(authCtx.userData.ContentAccessType, authCtx.userData.Period);
    const currentPlanTier = currentPlan !== undefined ?  GetPlanTitle(currentPlan) : undefined;
    const currentPlanTitle = currentPlan !== undefined && currentPlanTier !== undefined?  GetPlanPeriod(currentPlan) + "ly " + currentPlanTier : "";
    const currentDeviceLimit = currentPlanTier !== undefined ? DeviceLimit(currentPlanTier) : 0;

    const device = currentDeviceLimit > 1 ? " devices." : " device.";

    const normalText    = currentPlan !== undefined && currentPlanTier !== undefined
    ? "Your current plan is a " +
      currentPlanTitle +
      " that allows " +
      + currentDeviceLimit + device
    : undefined;

    const subtext = [
        currentPlan === PlanType.TruthSeeker ?  "Your Current plan is " + currentPlanTier + " that allows " + currentDeviceLimit + device : normalText,
        currentPlan === PlanType.TruthSeeker ? "The benefits of the new plan will be unlocked instantly." : undefined
    ];

    return (
        <Modal
            title={"Upgrade Subscription"}
            subText={subtext}
            display={props.display}
            setDisplay={props.setDisplay}
        >
            <UpgradeContainer>
                <Upgrade
                    display={props.display}
                    setDisplay={props.setDisplay}
                    setSuccess={props.setSuccess}
                />
            </UpgradeContainer>
        </Modal>
    );
}

export default UpgradeModal;
