import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ICarouselDTO from "../../../../Models/DTOs/ICarouselDTO";
import GenericCarousel from "../../../../Components/Carousels/GenericCarousel";
import { GetCategoryFilmsCarousel, GetCategorySeries, GetNewAndCurrentAffairs } from "../../../../Api/Carousel";
import InfiniteScroll from "react-infinite-scroll-component";
import { AxiosError } from "axios";
import { RoutePaths } from "../../../../Constants/RoutePaths";
import { ContentType } from "../../../../Models/Enums/ContentType";
import PortraitList from "../../../../Components/UI/List/PortraitList";
import { ResponsiveBreakpoints } from "../../../../Constants/ResponsiveBreakpoints";
import React from "react";
import PopUp from "../../../../Components/UI/Modals/PopUp";
import { PopUpType } from "../../../../Models/Enums/PopUpType";
import { BannerType } from "../../../../Models/Enums/BannerType";
import Banner from "../../../../Components/UI/Banner";
import ListContainer from "../../../../Components/UI/List/ListContainer";
import VideoSingleLoader from "../../../../Components/UI/PageLoaders/VideoSingleLoader";
import HeadingLoader from "../../../../Components/UI/PageLoaders/HeadingLoader";
import { SetTitle } from "../../../../Helpers/Utility";

// Context
import { UserAuthenticationContext } from "../../../../Context/UserAuthenticationContext";
import { toast } from "react-toastify";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
        padding: 1.5rem;
    }
`;

const DummCarouselWrapper = styled.div`
    margin: 3rem 0;
`;

function CategoryDetailsScreenFree(props: { popUpType?: PopUpType; bannerType?: BannerType; id: number; featuredImage?: string }) {
    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();

    const { id, bannerType, popUpType } = props;

    const [hasMore, setHasMore] = useState<boolean>(true);
    const [scrollCarousels, setScrollCarousels] = useState<ICarouselDTO[]>([]);
    const [films, setFilms] = useState<ICarouselDTO | null>(null);
    const [series, setSeries] = useState<ICarouselDTO[] | null>(null);
    const [news, setNews] = useState<ICarouselDTO | null>(null);
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

    useEffect(() => {
        return () => {
            //Resets scroller items on unmount
            setScrollCarousels([]);
            setHasMore(true);
        };
    }, [id]);

    useEffect(() => {
        SetTitle(bannerType === BannerType.WeeklyShows ? 'Weekly Shows' : 'David Icke');
    }, [bannerType]);

    useEffect(() => {
        // Wait until user data is available
        if (!authCtx.userData) {
            return;
        }

        async function fetchInitialData() {
            setLoadingInitial(true);

            const controller = new AbortController();
            const userId = authCtx.userData.AspNetUserId;
            const countryCode = authCtx.userData.CurrentCountryCode;

            // Fetch films
            const filmsResult = await GetCategoryFilmsCarousel(id, countryCode, userId, 5, 0);
            if (!filmsResult || filmsResult instanceof AxiosError) {
                navigate(RoutePaths.Browse);
                return;
            }

            // Fetch series
            const seriesResult = await GetCategorySeries(id, userId, 3, 0, 5);
            if (!seriesResult || seriesResult instanceof AxiosError) {
                navigate(RoutePaths.Browse);
                return;
            }

            // Fetch news if category id is 13
            let newsResult: ICarouselDTO | null = null;
            if (id === 13) {
                const newsData = await GetNewAndCurrentAffairs(userId);
                if (newsData && !(newsData instanceof AxiosError) && newsData.Content && newsData.Content.length > 0) {
                    const manualOrder: string[] = [
                        "Health Code with Jaymie Icke",
                        "Classified with Richard Willett",
                        "Gareth Icke Tonight",
                        "The Dot Connector with David Icke",
                        "Wake Up Call with Kristen Bitting",
                        "WTAF With Rich & Gaz",
                    ];

                    newsData.Content.sort((a, b) => {
                        return manualOrder.indexOf(a.Title) - manualOrder.indexOf(b.Title);
                    });

                    newsResult = newsData;
                } else {
                    newsResult = null;
                }
            }

            setFilms(filmsResult);
            setSeries(seriesResult);
            setNews(newsResult);
            setLoadingInitial(false);
        }

        fetchInitialData();
    }, [authCtx.userData, id, navigate]);

    async function GetContent(contentId: number, skip: number) {
        const take = 3;
        const userId = authCtx.userData?.AspNetUserId;
        const result = await GetCategorySeries(contentId, userId, take, skip, 5);

        if (!result || result instanceof AxiosError || result.length <= 0) {
            setHasMore(false);
            return;
        }

        setScrollCarousels((prev) => [...prev, ...result]);
    }

    const loadMoreLoader = (paddingTop: number) => {
        return (
            <>
                <HeadingLoader paddingTop={`${paddingTop}`} />

                <ListContainer>
                    {[...Array(5)].map((_, i) => (
                        <VideoSingleLoader paddingTop="8" key={i} />
                    ))}
                </ListContainer>
            </>
        );
    };

    if (loadingInitial) {
        return (
            <>
                <Banner apiEnum={bannerType} />
                {popUpType !== undefined && (
                    <PopUp canBeDismissed={true} apiEnum={popUpType} isFullScreen={true} />
                )}
                <Container>
                    {loadMoreLoader(0)}
                    <DummCarouselWrapper>{loadMoreLoader(0)}</DummCarouselWrapper>
                    <DummCarouselWrapper>{loadMoreLoader(0)}</DummCarouselWrapper>
                </Container>
            </>
        );
    }

    // If after loading, no films or series found, redirect or handle error
    if (!films || !series) {
        toast.error("No content found for this category.");
        navigate(RoutePaths.Browse);
        return null;
    }

    let hasFilms = false;
    let carousels: ICarouselDTO[] = [];

    if (films.Content && films.Content.length > 0) {
        hasFilms = true;
        carousels = [films, ...series];
    } else {
        carousels = series;
    }

    const combinedCarousels = [...carousels, ...scrollCarousels];
    const length = hasFilms ? combinedCarousels.length - 1 : combinedCarousels.length;

    return (
        <React.Fragment key={id}>
            <Banner apiEnum={bannerType} />

            {popUpType !== undefined && (
                <PopUp
                    canBeDismissed={true}
                    apiEnum={popUpType}
                    isFullScreen={true}
                />
            )}

            <Container>
                {news && (
                    <PortraitList carousel={news} height={225} mobileHeight={500} shrink={true} />
                )}

                <InfiniteScroll
                    dataLength={length}
                    hasMore={hasMore}
                    next={() => GetContent(id, length)}
                    loader={
                        <DummCarouselWrapper>
                            {loadMoreLoader(0)}
                        </DummCarouselWrapper>
                    }
                >
                    {combinedCarousels.map((content, i) => {
                        let contentLink = undefined;

                        if (content.ContentId !== undefined && content.ContentType !== undefined) {
                            switch (content.ContentType) {
                                case ContentType.Film:
                                    contentLink = RoutePaths.CategoryFilms(content.ContentId);
                                    break;
                                case ContentType.Series:
                                    contentLink = RoutePaths.SeriesEpisodes(content.ContentId);
                                    break;
                                default:
                                    break;
                            }
                        }

                        return (
                            <GenericCarousel
                                key={i}
                                carousel={content}
                                viewMoreText="View All"
                                viewMoreLocation={contentLink}
                                AmountToShow={5}
                            />
                        );
                    })}
                </InfiniteScroll>
            </Container>
        </React.Fragment>
    );
}

export default CategoryDetailsScreenFree;

// No loader function needed since data fetching is now handled inside the component.
