import React from "react";

const IconInformationBubble = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M2 4v16.002a1 1 0 001.625.781l4.725-3.781H21a1 1 0 001-1V4a1 1 0 00-1-1H3a1 1 0 00-1 1zm18.5 11.502H7.823L3.5 18.962V4.5h17zm-8.502-6.5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5a.75.75 0 01.75-.75zm.002-3a1 1 0 110 2 1 1 0 010-2z"
            ></path>
        </svg>
    )
};

export default IconInformationBubble;
