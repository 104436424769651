import React, { useContext, useEffect, useRef, useState, Fragment } from "react";
import {
    redirect,
    useParams,
} from "react-router-dom";
import styled from "styled-components";
import {GetCategory, GetCategoryArticles} from "../../Api/Article";
import { RoutePaths } from "../../Constants/RoutePaths";
import {debounce, SetTitle} from "../../Helpers/Utility";
import ICategoryArticlesDTO from "../../Models/DTOs/ICategoryArticlesDTO";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import IArticleCategoryLatestDTO from "../../Models/DTOs/IArticleCategoryLatestDTO";
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";
import ArticleGridItemSingle from "../../Components/UI/Article/ArticleGridItemSingle";
import Heading from "../../Components/UI/Text/Heading";
import HeadingLoader from "../../Components/UI/PageLoaders/HeadingLoader";
import VideoSingleLoader from "../../Components/UI/PageLoaders/VideoSingleLoader";
import NavLinkSmallInlineGreyButton from "../../Components/UI/Buttons/NavLinkSmallInlineGreyButton";
import PopUp from "../../Components/UI/Modals/PopUp";
import {PopUpType} from "../../Models/Enums/PopUpType";
import {IsAuthenticated} from "../../Helpers/UserUtility";
import IPartnerDTO from "../../Models/DTOs/IPartnerDTO";
import BannerAd from "../../Components/UI/Ads/BannerAd";

// Context
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

const
    Container = styled.section`
        width: 100%;
        max-width: calc(1600rem/16);
        margin: 0 auto;
        box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5), 0 calc(1rem/16) 0 0 rgba(0,0,0,0.5);
    `,
    ArticlesGrid = styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: calc(16rem/16);
        padding: 1rem;

        // For the dummy loader items
        > div {
            @media screen and (min-width: calc(${ResponsiveBreakpoints.MediumMobileBreakpoint}rem/16)) {
                width: calc(50% - (8rem/16));
            }

            @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
                width: calc(50% - (16rem/16));
                margin: 0 0 3rem 0;
            }

            @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}rem/16)) {
                width: calc(25% - (24rem/16));
            }
        }

        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
            padding: 2rem 2rem 0 2rem;
            gap: calc(32rem/16);
        }
    `,
    HeadingContainer = styled.div`
        parring: 1rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        > p {
            width: 100% !important;
        }

        .heading {
            margin: 1rem 0;
            text-align: center;
            font-size: 14pt;
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
                font-size: 18pt;
            }

            @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
                font-size: 25pt;
            }
        }

        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
            padding: 2rem 2rem 0 2rem;
        }
    `,
    AdContainer = styled.div`
        margin: 0 0 1.5rem 0;
        &:last-child {
            margin: 0;
        }
    `;

function ArticleCategoryScreen() {
    const params = useParams();
    let categoryId = Number(params.id);

    const
        authCtx = useContext(UserAuthenticationContext),
        controller = new AbortController(),
        take = 8,
        loadedSinceLastAd = useRef<number>(0),
        endOfListRef = useRef<boolean>(false),
        containerRef = useRef<HTMLDivElement|null>(null),
        [ category, setCategory ] = useState<IArticleCategoryLatestDTO|null>(null),
        [ articles, setArticles ] = useState<ICarouselContentDTO[]>([]),
        [ ads, setAds ] = useState<IPartnerDTO[]>([]),
        [ canFetch, setCanFetch ] = useState<boolean>(false),
        [ isLoading, setIsLoading ] = useState<boolean>(true),
        fetchCategoryData = () => {
            GetCategory(categoryId, 3, controller)
                .then(response => {
                    const categoryData = response as IArticleCategoryLatestDTO;

                    if (categoryData === null) {
                        return redirect(RoutePaths.BrowseArticles);
                    }

                    SetTitle(categoryData.Category.Title);
                    setCategory(categoryData);
                    setCanFetch(true);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        fetchArticles = () => {
            GetCategoryArticles(
                categoryId,
                articles.length,
                take,
                loadedSinceLastAd.current,
                controller
            )
                .then(response => {
                    const articlesData = response as ICategoryArticlesDTO;

                    if (articlesData.Articles !== null) {
                        const newArticlesList = [...articles, ...articlesData.Articles];

                        if (articlesData.Partner !== null && articlesData.Partner !== undefined) {
                            loadedSinceLastAd.current = 0;
                        }

                        setArticles(newArticlesList);
                    } else {
                        endOfListRef.current = true;
                        setIsLoading(false);
                    }

                    if (articlesData.Partner !== null && articlesData.Partner !== undefined) {
                        const newPartnerList = [...ads, articlesData.Partner];
                        setAds(newPartnerList);
                    }

                })
                .catch(error => {
                    console.log(error);
                })
        };

    // On mount
    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight
                && !endOfListRef.current
            ) {
                setCanFetch(true);
            }
        };

        // scroll listener for infinite load
        const debouncedHandleScroll = debounce(() => handleScroll(), 200);
        window.addEventListener('scroll', debouncedHandleScroll);

        // Unmount
        return () => {
            setArticles([]);
            setCategory(null);
            setCanFetch(false);
            setIsLoading(true);
            endOfListRef.current = false;
            loadedSinceLastAd.current = 0;
            window.removeEventListener('scroll', debouncedHandleScroll);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (category === null) {
            fetchCategoryData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ category ]);

    useEffect(() => {
        if (canFetch) {
            setCanFetch(false);
            fetchArticles();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ canFetch ]);

    useEffect(() => {
        if (
            !endOfListRef.current &&
            containerRef.current &&
            (containerRef.current.clientHeight + 380) <= window.innerHeight &&
            articles.length > 0
        ) {
            setTimeout(() => {
                setCanFetch(true);
            }, 1000)
        }
    }, [ articles ]);

    return (
        <Container ref={containerRef}>
            {!IsAuthenticated(authCtx.userData) && (
                <PopUp
                    canBeDismissed={true}
                    apiEnum={PopUpType.Read}
                    isFullScreen={true}
                />
            )}

            <HeadingContainer>
                {category && category.Category ?
                    (
                        <>
                            <Heading className="heading">
                                Browsing: {category.Category.Title}
                            </Heading>

                            <NavLinkSmallInlineGreyButton to={RoutePaths.BrowseArticles}>
                                Back
                            </NavLinkSmallInlineGreyButton>
                        </>
                    )
                    :
                    (
                        <HeadingLoader paddingTop="0"/>
                    )
                }
            </HeadingContainer>

            <ArticlesGrid>
                {articles && articles.length > 0 ?
                    articles.map((article, i) => (
                        <Fragment key={i}>
                            <ArticleGridItemSingle
                                key={i}
                                article={article}
                            />

                            {
                                (i + 1) % 8 === 0 && (() => {
                                    const adIndex = Math.floor((i + 1) / 8) % ads.length;
                                    if (adIndex >= 0 && adIndex < ads.length) {
                                        return (
                                            <AdContainer>
                                                <BannerAd partner={ads[adIndex]} />
                                            </AdContainer>
                                        );
                                    }
                                    return null;
                                })()
                            }
                        </Fragment>
                    ))
                    :
                    null
                }

                {isLoading ?
                    <>
                        <VideoSingleLoader paddingTop="0"/>
                        <VideoSingleLoader paddingTop="0"/>
                        <VideoSingleLoader paddingTop="0"/>
                        <VideoSingleLoader paddingTop="0"/>
                    </> : null
                }
            </ArticlesGrid>
        </Container>
    );
}

export default ArticleCategoryScreen;
