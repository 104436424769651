import { useContext, useState } from 'react';
import IPlanDTO from "../../../Models/DTOs/IPlanDTO";
import { PlanPeriod } from "../../../Models/Enums/PlanPeriod";
import SelectPlan from "../Subscription/SelectPlan";
import SelectPaymentMethod from "../Subscription/SelectPaymentMethod";
import PlanSummary from "../Subscription/PlanSummary";
import { IsNullOrWhiteSpace } from '../../../Helpers/Utility';
import PrimaryText from '../Text/PrimaryText';

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

function Upgrade(props: {
    display: boolean,
    setDisplay: (value: boolean) => void,
    showSameLevelPlans?: boolean,
    setSuccess?: (value: boolean) => void
}) {
    const authCtx = useContext(UserAuthenticationContext);

    const [selectedPeriod, setSelectedPeriod] = useState<PlanPeriod>(
        PlanPeriod.Yearly
    );
    const [selectedPlan, setSelectedPlan] = useState<IPlanDTO>();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>(authCtx?.userData.PaymentMethodId);
    const showSameLevelPlans: boolean = props.showSameLevelPlans ?? true;
    const [showedRecapOnce, setShowRecapOnce] = useState<boolean>(false);

    function SetSelectedPlan(plan?: IPlanDTO) {
        setSelectedPlan(plan);
    }

    function SetSelectedPeriod(period: PlanPeriod) {
        setSelectedPeriod(period);
    }

    function SetSelectedPaymentMethod(paymentMethod?: string) {
        setSelectedPaymentMethod(paymentMethod);
    }

    // Appears once the user has selected a plan and a payment method final step
    if (!IsNullOrWhiteSpace(selectedPaymentMethod) && selectedPlan !== undefined) {
        return (
            <PlanSummary
                selectedPlan={selectedPlan}
                setSelectedPlan={SetSelectedPlan}
                setSelectedPaymentMethod={SetSelectedPaymentMethod}
                setDisplay={props.setDisplay}
                isUpgrade={true}
                skipRecap={showedRecapOnce}
                setShowedRecap={setShowRecapOnce}
                setSuccess={props.setSuccess}
            />
        );
    }

    // Appears when the user hasn't selected a plan first step
    if (selectedPlan === undefined) {
        return (
            <SelectPlan
                setSelectedPlan={SetSelectedPlan}
                setSelectedPeriod={SetSelectedPeriod}
                selectedPeriod={selectedPeriod}
                showSameLevelPlans={showSameLevelPlans}

            />
        );
    } else if (props.display) {
        return (
            <>
                <PrimaryText>The upgrade couldn't go through with your current payment method. Please ensure your details are valid or try a new one.</PrimaryText>
                <SelectPaymentMethod // Select payment method, step 2
                    setSelectedPaymentMethod={SetSelectedPaymentMethod}
                    allowUseExistingCard={false}
                />
            </>
        );
    }

    return (
        <></>
    )
}

export default Upgrade;
