import React from "react";

const IconSpinLoader = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm8 12a8 8 0 11-16 0 8 8 0 0116 0zM1 12C1 5.935 5.935 1 12 1v2c-4.962 0-9 4.038-9 9a8.972 8.972 0 002.639 6.361l-1.414 1.414.015.014A10.97 10.97 0 011 12z"> </path>
        </svg>
    )
};

export default IconSpinLoader;
